import { createSlice } from '@reduxjs/toolkit';

const initialBTPState = {
  projectCreating: false,
  projectsReceived: false,
  projectSending: false,
  projectsEntities: [],
  error: null,
};

export const callTypes = {
  createProject: 'createProject',
  getProjects: 'getProjects',
  sendProjects: 'sendProjects',
};

export const calculationBTPSlice = createSlice({
  name: 'calculationBTP',
  initialState: initialBTPState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.createProject) {
        state.projectCreating = false;
        return;
      }

      if (action.payload.callType === callTypes.getProjects) {
        state.projectsReceived = false;
        return;
      }

      if (action.payload.callType === callTypes.sendProjects) {
        state.projectSending = false;
        return;
      }
    },

    startCall: (state, action) => {
      state.error = null;

      if (action.payload.callType === callTypes.createProject) {
        state.projectCreating = true;
        return;
      }

      if (action.payload.callType === callTypes.getProjects) {
        state.projectsReceived = true;
        return;
      }

      if (action.payload.callType === callTypes.sendProjects) {
        state.projectSending = true;
        return;
      }
    },

    projectBTPCreated: (state, action) => {
      state.projectCreating = false;
      state.error = null;
    },

    projectsBTPReceived: (state, action) => {
      if (action.payload.data) {
        const { entities } = action.payload.data;
        state.projectsEntities = entities;
      }

      state.projectsReceived = false;
      state.error = null;
    },

    projectsBTPIFrameSended: (state, action) => {
      state.projectSending = false;
      state.error = null;
    },
  },
});
