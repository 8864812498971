import React, { createContext, useContext, useState, useCallback } from 'react';
import { isEqual, isFunction } from 'lodash';

import { initialFilter } from './ScadaListUIHelpers';

const ScadaListUIContext = createContext();

export function useScadaListUIContext() {
  return useContext(ScadaListUIContext);
}

export const ScadaListUIConsumer = ScadaListUIContext.Consumer;

export const ScadaListUIProvider = ({ children }) => {
  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const setQueryParams = useCallback(nextQueryParams => {
    setQueryParamsBase(prevQueryParams => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);

  const value = {
    queryParams,
    setQueryParamsBase,
    setQueryParams,
  };

  return <ScadaListUIContext.Provider value={value}>{children}</ScadaListUIContext.Provider>;
};
