import React, { useCallback, useEffect, useState } from 'react';
import SVG from 'react-inlinesvg'
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';

import * as objActions from "../../modules/UserInfo/_redux/myObjects/myObjectsActions";
import { toAbsoluteUrl } from '../../../_metronic/_helpers/AssetsHelpers';
import { ObjectsEditDialog } from '../../modules/UserInfo/pages/myObjects/objects-edit-dialog/ObjectsEditDialog';

export const ObjSelectWidget = ({ selectedObj, setSelectedObj, formikRef, name='object', isDisabled=false }) => {
  const [objVal, setObjVal] = useState([]);
  const [showModalObjCreate, setShowModalObjCreate] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(objActions.getAllMyObjects());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { objState } = useSelector(
    (state) => ({
      objState: state.myObjects,
    }),
    shallowEqual
  );

  const { lastCreatedObjId, allEntities } = objState;

  useEffect(() => {
    if (allEntities) {
      setObjVal(allEntities.map(obj => ({ value: Number(obj.id), label: obj.name })));
    };
  }, [allEntities]);

  useEffect(() => {
    if (lastCreatedObjId) {
      const createdObj = allEntities.find(obj => obj.id === lastCreatedObjId);
      createdObj && setSelectedObj({ value: createdObj.id, label: createdObj.name });
      formikRef.current.setFieldValue(name, createdObj.id);
      dispatch(objActions.clearLastCreatedObjectId());
    };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allEntities]);

  const handleObjectChange = useCallback(val => {
    formikRef && formikRef.current.setFieldValue(name, String(val.value));
    setSelectedObj(val);
  }, [formikRef, name, setSelectedObj]);

  return (
    <>
      <Select
        value={selectedObj}
        placeholder="Выберите объект"
        name={name}
        onChange={handleObjectChange}
        classNamePrefix="react-select"
        className={'react-select'}
        options={objVal}
        isDisabled={isDisabled}
      />
      <button
        type="button"
        className="btn btn-icon btn-light btn-hover-primary btn-lg ml-2"
        onClick={() => setShowModalObjCreate(true)}
        disabled={isDisabled}
      >
        <span className="svg-icon svg-icon-md svg-icon-primary">
          <SVG src={toAbsoluteUrl('/media/svg/icons/Code/Plus.svg')} />
        </span>
      </button>
      <ObjectsEditDialog
        show={showModalObjCreate}
        onHide={() => {
          setShowModalObjCreate(false);
        }}
      />
    </>
  );
};