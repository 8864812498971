import * as requestFromServer from './homeCrud';
import { homeSlice, callTypes } from './homeSlice';

const { actions } = homeSlice;

export const fetchDashboardData = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.dashData }));
  return requestFromServer
    .dashboardData(queryParams)
    .then(response => {
      const { data } = response;
      dispatch(actions.dashboardFetched({ data }));
    })
    .catch(error => {
      error.clientMessage = "Can't find dashboard data!";
      dispatch(actions.catchError({ error, callType: callTypes.dashData }));
    });
};
