import React from 'react';

import { RegistrationUIProvider,  } from './RegistrationUIContext';
import { RegistrationCard } from './RegistrationCard';

export const Registration = () => {
  return (
    <>
      <RegistrationUIProvider>
        <RegistrationCard />
      </RegistrationUIProvider>
    </>
  );
};