import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Card, CardBody, CardFooter, CardHeader } from '../../../../../_metronic/_partials/controls';
import { Step1 } from './components/Step1';
import { Step4 } from './components/Step4/Step4';
import { HeadSteps } from './components/HeadSteps';
import { useScannerUIContext } from './ScannerUIContext';
import { Step2 } from './components/Step2';
import { stepName } from './ScannerUIHelpers';
import * as actions from '../../redux/scannerr/scannerActions';
import { CardSpinner } from '../../../../widgets/cardSpinner/CardSpinner';
import { Step3 } from './components/Step3';
import { Step5 } from './components/Step5';
import { ModalInfo } from '../../../../widgets/modalInfo/ModalInfo';

const scannerText = 'Данный раздел находится на стадии тестирования, мы не гарантируем его полной работоспособности. Если у вас произошла ошибка во время работы напишите нам в разделе “Обращения”.  Спасибо за понимание.';

export const ScannerCard = () => {
  const [step, setStep] = useState(0);
  const [showModal, setShowModal] = useState(true);
  const dispatch = useDispatch();

  const scannerUIContext = useScannerUIContext();
  const scannerUIProps = useMemo(() => {
    return {
      scanFile: scannerUIContext.scanFile,
      setScanFile: scannerUIContext.setScanFile,
      formDataStep3: scannerUIContext.formDataStep3,
      setFormDataStep3: scannerUIContext.setFormDataStep3,
      formDataStep1: scannerUIContext.formDataStep1,
      formDataStep4: scannerUIContext.formDataStep4,
      setFormDataStep4: scannerUIContext.setFormDataStep4,
      victoriaRun: scannerUIContext.victoriaRun,
      checkedPoss: scannerUIContext.checkedPoss,
      setCheckedPoss: scannerUIContext.setCheckedPoss,
    };
  }, [scannerUIContext.checkedPoss, scannerUIContext.formDataStep1, scannerUIContext.formDataStep3, scannerUIContext.formDataStep4, scannerUIContext.scanFile, scannerUIContext.setCheckedPoss, scannerUIContext.setFormDataStep3, scannerUIContext.setFormDataStep4, scannerUIContext.setScanFile, scannerUIContext.victoriaRun]);

  const { currentState } = useSelector(state => ({ currentState: state.scanner }));
  const { scanLoading, checkedScanLoading, getScanLoading, pdfFile, foundedScanList, decodedScanList } = currentState;

  const initialFilter = {
    filter: {
      date: '',
      status: '',
      title: '',
    },
    sortOrder: 'desc',
    sortField: 'id',
  };

  useEffect(() => {
    dispatch(actions.fetchAllOrders(initialFilter));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeStep = useCallback(() => {
    if (step < stepName.length - 1) {
      if (step === 0) {
        setStep(step + 1);
      };

      if (step === 1 && scanLoading) {
        return;
      }

      if (step === 1 && scannerUIProps.scanFile.file) {
        dispatch(actions.fetchScanSend(scannerUIProps.scanFile))
          .then(response => {
            scannerUIProps.setFormDataStep3({ ...scannerUIProps.formDataStep3, uniqid: response.uniqid })
            return response.uniqid;
          })
          .then(uniqid => {
            dispatch(actions.fetchGetScanFile({ uniqid: uniqid }))
          })
          .then(() => setStep(step + 1))
        ;
        return;
      };

      if (step === 2 && scannerUIProps.formDataStep3.checkedPage.length > 0) {
        dispatch(actions.fetchCheckedScanPages({ ...scannerUIProps.formDataStep3 }))
          .then(() => setStep(step + 1));
        return;
      };

      if (step === 3) {
        const { checkedPage, ...step3State } = scannerUIProps.formDataStep3;
        const module = scannerUIProps.formDataStep1.section;
        const { value } = scannerUIProps.formDataStep1.order;
        const { checkedPoss } = scannerUIProps.formDataStep4;
        const checkedProducts = [];

        checkedPoss.forEach(pos => {
          const index = decodedScanList.findIndex(prod => prod.pos === pos);
          if (index !== -1) {
            const prod = { ...decodedScanList[index] };
            const foundProdIndex = foundedScanList.findIndex(prod => prod.pos === pos);

            if (foundProdIndex !== -1) {
              prod.newCid = foundedScanList[foundProdIndex].cid;
            };

            checkedProducts.push({ ...prod });
          };
        });

        dispatch(actions.sendSelectedProducts({
          ...step3State,
          module: module,
          pid: value,
          products: [...checkedProducts],
        }))
          .then(() => setStep(step + 1));
        return;
      };

      return;
    }
  }, [decodedScanList, dispatch, foundedScanList, scanLoading, scannerUIProps, step]);

  const handleChangeStepBack = () => {
    scannerUIProps.setFormDataStep4({ ...scannerUIProps.formDataStep4, checkedPoss: [] });

    if (step > 0) {
      setStep(step - 1);
    };
  };

  const isNextButtonDisabled = () => {
    if (step === 1) {
      return !scannerUIProps.scanFile.file ? true : false;
    };

    if (step === 2) {
      return scannerUIProps.formDataStep3.checkedPage.length > 0 ? false : true;
    };

    if (step === 3) {
      return scannerUIProps.formDataStep4.checkedPoss.length > 0 ? false : true;
    };

    return false;
  };

  const stepContent = useMemo(() => {
    return (
      [
        <Step1 />,
        <Step2 />,
        <Step3 pdfFile={pdfFile} />,
        <Step4 />,
        <Step5 />,
      ][step]
    )
  }, [pdfFile, step]);

  return (
    <>
      {(scanLoading || getScanLoading || checkedScanLoading) && <CardSpinner />}
      <ModalInfo
        showModal={showModal}
        setShowModal={setShowModal}
        text={scannerText}
        titleButton={false}
        rightBtnText={'OK'}
        rightBtnAction={() => setShowModal(false)}
      />
      <Card>
        <CardHeader>
          <HeadSteps step={step} />
        </CardHeader>
        <CardBody>
          <div className="row justify-content-center px-8">
            <div className="col-12">
              {stepContent}
            </div>
          </div>
        </CardBody>
        <CardFooter>
          <div className="row justify-content-center px-8">
            <div className="text-right col-12">
              <button
                type="button"
                className="btn btn-primary font-weight-bolder text-uppercase px-9 py-4 mr-3"
                onClick={handleChangeStepBack}
              >
                Назад
              </button>
              <button
                type="button"
                className="btn btn-primary font-weight-bolder text-uppercase px-9 py-4"
                onClick={handleChangeStep}
                disabled={isNextButtonDisabled()}
              >
                Далее
              </button>
            </div>
          </div>
        </CardFooter>
      </Card>
    </>
  );
};