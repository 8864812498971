import React from "react";
import { InfoToolTips } from "../../widgets/infoToolTips";
import { HomeCard } from "./HomeCard";

export const HomePage = () => {
  return (
    <>
      <HomeCard />
      <InfoToolTips />
    </>
  );
}
