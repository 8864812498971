import React, { useEffect, useState } from 'react';
import * as action from './../_redux/authActions';
import { useDispatch } from 'react-redux';
import store from '../../../../redux/store';

export const EndPoint = ({}) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(action.fetchEndPoint());
  }, []);
  const avatar = store.getState().auth.endPointAvatar;
  const name = store.getState().auth.endPointName;
  const surname = store.getState().auth.endPointSurname;
  const is_endpoint = store.getState().auth.isEndPoint;
  const token = store.getState().auth.endPointToken;

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const handleEndpoint = async () => {
    enableLoading();
    await dispatch(action.endPointLogin(token));
    dispatch(action.fetchUser()); 
    disableLoading();
  };

  return (
    is_endpoint && (
      <div className="card card-custom card-endpoint gutter-b">
        <div className="card-body">
          <div className="d-flex align-items-center">
            <div className="symbol symbol-30 symbol-lg-40 symbol-circle mr-3">
              <img alt="Avatar" src={avatar} />
            </div>
            <div className="d-flex align-items-center justify-content-between w-100">
              <div className="d-flex flex-column text-left">
                <span className="text-endpoint font-weight-bold">Продолжить как</span>
                <span className="text-dark-75 font-weight-bold">
                  {surname} {name}
                </span>
              </div>
              <div className="d-flex text-right">
                <button
                  type="button"
                  onClick={handleEndpoint}
                  className="btn btn-light btn-text-success btn-hover-text-success font-weight-bold"
                >
                  Войти
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  );
};
