import React from 'react';
import { Button } from 'react-bootstrap';
import SVG from "react-inlinesvg";
import { Modal } from 'react-bootstrap';
import { toAbsoluteUrl } from '../../../_metronic/_helpers';

export const ModalInfo = ({ showModal, setShowModal, title = 'Внимание!', text = '', leftBtnText = '' , leftBtnAction = null, rightBtnText = '' , rightBtnAction = null, titleButton = true }) => {
  return (
    <Modal show={showModal} onHide={setShowModal} animation={true}>
      <Modal.Header>
        <Modal.Title>{title}</Modal.Title>
        {titleButton ? (<Button className="p-1" variant="secondary" onClick={()=>setShowModal(false)}>
          <span className="svg-icon-md">
            <SVG src={toAbsoluteUrl("/media/svg/icons/Navigation/Close.svg")} className="h-50 align-self-center"></SVG>
          </span>
        </Button>) : null}
      </Modal.Header>
      <Modal.Body>{text}</Modal.Body>
      <Modal.Footer>
        {leftBtnText && <Button variant="primary" onClick={()=>leftBtnAction()}>{leftBtnText}</Button>}
        {rightBtnText && <Button variant="primary" onClick={()=>rightBtnAction()}>{rightBtnText}</Button>}
      </Modal.Footer>
    </Modal>
  );
}