import React from 'react';
import store from '../../../../../../../redux/store';
import BootstrapTable from 'react-bootstrap-table-next';
import { Button } from 'react-bootstrap';

const { REACT_APP_MODE, REACT_APP_REQ_PROD_API, REACT_APP_REQ_DEV_API } = process.env;
const ENDPOINT_API = REACT_APP_MODE === 'dev' ? REACT_APP_REQ_DEV_API : REACT_APP_REQ_PROD_API;

export const ScadaShowVarTable = ({ scadaShowVarEntities }) => {
  const token = store.getState().auth.authToken;
  const columns = [
    {
      dataField: 'id',
      text: '#',
      sort: true,
    },
    {
      dataField: 'title',
      text: 'Наименование',
      sort: true,
    },
    {
      dataField: 'type',
      text: 'Тип',
      sort: true,
    },

    {
      dataField: 'socet',
      text: 'Значение',
      sort: true,
      formatter: (cell, _row, _rowIndex) => {
        if (typeof cell.value === 'number') {
          return cell.value.toFixed(3);
        }

        return cell.value;
      },
      style: {
        width: '100px',
      },
    },
    {
      dataField: 'action',
      text: 'Действие',
      sort: true,
      formatter: (_cell, row, _rowIndex) => {
        if (row.actions == 'ReadWrite') {
          if (row.field_type == 'ErrorReset') {
            return (
              <Button
                variant="secondary"
                size="sm"
                onClick={async () => {
                  const value = window.confirm('Вы уверены что хотите сбросить все аварии?');
                  if (value) {
                    try {
                      const form = new FormData();
                      form.append('tag_id', row.id);
                      form.append('value', true);
                      const result = await (
                        await fetch(`${ENDPOINT_API}v1/scada/action`, {
                          headers: {
                            Accept: 'application/json',
                            Authorization: `Bearer ${token}`,
                          },
                          method: 'POST',
                          body: form,
                        })
                      ).text();
                    } catch (e) {
                      alert(e.message);
                    }
                  }
                }}
              >
                Сбросить аварию
              </Button>
            );
          } else if (row.field_type == 'Input') {
            return (
              <Button
                variant="secondary"
                size="sm"
                onClick={async () => {
                  const value = prompt('Укажите необходимое значение');
                  if (value) {
                    try {
                      const form = new FormData();
                      form.append('tag_id', row.id);
                      form.append('value', value);
                      const result = await (
                        await fetch(`${ENDPOINT_API}v1/scada/action`, {
                          headers: {
                            Accept: 'application/json',
                            Authorization: `Bearer ${token}`,
                          },
                          method: 'POST',
                          body: form,
                        })
                      ).text();
                    } catch (e) {
                      alert(e.message);
                    }
                  }
                }}
              >
                Изменить значение
              </Button>
            );
          } else {
            return '';
          }
        }
      },
      style: {
        width: '100px',
      },
    },
  ];

  return (
    <BootstrapTable
      wrapperClasses="table-responsive"
      bordered={false}
      classes="table table-head-custom table-vertical-center overflow-hidden"
      bootstrap4
      remote
      keyField="id"
      data={scadaShowVarEntities}
      columns={columns}
    />
  );
};
