import React from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment/min/moment-with-locales';

import { Card, CardBody, Notice } from '../../../_metronic/_partials/controls';
import { CardSpinner } from '../../widgets/cardSpinner/CardSpinner';
import * as actions from './redux/promotionsActions';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../Routes.models';
import { toAbsolutePublicUrl } from '../../../_metronic/_helpers';

export const PromotionsCard = () => {
  const dispatch = useDispatch();

  const { currentState } = useSelector(state => ({ currentState: state.promotions }));
  const { promotionsLoading, promotionsEntities } = currentState;

  useEffect(() => {
    dispatch(actions.fetchPromotions());
  }, []);

  return (
    <>
      {promotionsLoading && <CardSpinner />}
      <div className="row">
        {promotionsEntities.length ? (
          promotionsEntities.map(promo => (
            <div key={promo.id} className="col-xl-3 col-lg-6 col-md-6 col-sm-6">
              <Card className="card-stretch">
                <CardBody className="">
                  <div className="h-100 d-flex flex-column">
                    <div className="row mb-7">
                      <div className="col-12">
                        <img className="w-100" src={`${toAbsolutePublicUrl(promo.preview.full_webp)}`} alt="promo" />
                      </div>
                    </div>
                    <h4 className="text-center pb-4">{promo.title}</h4>
                    <p className="flex-grow-1 mb-7 text-justify">{promo.description}</p>
                    <p className="text-center font-size-lg text-dark-75 font-weight-bolder mb-7">
                      {`${moment(promo.date_start).format('DD.MM.YYYY')} - ${moment(promo.date_end).format(
                        'DD.MM.YYYY',
                      )}`}
                    </p>
                    <Link
                      className="btn btn-block btn-sm btn-light-primary font-weight-bolder text-uppercase py-4"
                      to={`${ROUTES.PROMOTIONS}/${promo.id}`}
                    >
                      Подробнее
                    </Link>
                  </div>
                </CardBody>
              </Card>
            </div>
          ))
        ) : (
          <Notice className="w-100" svg="/media/svg/icons/Code/Warning-1-circle.svg">
            <p className="m-0 ">На данный момент акций нет</p>
          </Notice>
        )}
      </div>
    </>
  );
};
