import React, { useMemo } from 'react';
import { Formik } from 'formik';
import { useIntl } from 'react-intl';
import { isEqual } from 'lodash';
import { useSelector } from 'react-redux';
import { useScadaListUIContext } from '../ScadaListUIContext';

const prepareFilter = (queryParams, values) => {
  const { status, searchText, oid, object } = values;
  const newQueryParams = { ...queryParams };
  const filter = {
    status: {
      operator: '',
      value: '',
    },

    oid: {
      operator: '',
      value: '',
    },

    object: {
      operator: '',
      value: '',
    },
  };

  if (status !== '') {
    filter.status.operator = '=';
    filter.status.value = status;
  } else {
    delete filter.status;
  }

  if (oid !== '') {
    filter.oid.operator = '=';
    filter.oid.value = oid;
  } else {
    delete filter.oid;
  }

  if (object !== '') {
    filter.object.operator = '=';
    filter.object.value = object;
  } else {
    delete filter.object;
  }

  newQueryParams.searchText = searchText;
  newQueryParams.filter = filter;

  return newQueryParams;
};

export const ScadaListFilter = () => {
  const intl = useIntl();
  const scadaListUIContext = useScadaListUIContext();

  const scadaUIProps = useMemo(() => {
    return {
      queryParams: scadaListUIContext.queryParams,
      setQueryParams: scadaListUIContext.setQueryParams,
    };
  }, [scadaListUIContext.queryParams, scadaListUIContext.setQueryParams]);

  const { orgState } = useSelector(state => ({
    orgState: state.myOrganisations,
  }));

  const { orgEntities } = { orgEntities: orgState.allEntities };

  const applyFilter = values => {
    const newQueryParams = prepareFilter(scadaUIProps.queryParams, values);
    if (!isEqual(newQueryParams, scadaUIProps.queryParams)) {
      newQueryParams.pageNumber = 1;
      scadaUIProps.setQueryParams(newQueryParams);
    }
  };

  return (
    <>
      <Formik
        initialValues={{
          status: '',
          oid: '',
          object: '',
          searchText: '',
        }}
        onSubmit={values => {
          applyFilter(values);
        }}
      >
        {({ values, handleSubmit, handleBlur, setFieldValue }) => (
          <form onSubmit={handleSubmit} className="form form-label-right">
            <div className="form-group row">
              <div className="col-lg-2">
                <input
                  className="form-control"
                  placeholder="Поиск"
                  name="searchText"
                  autoComplete="off"
                  onChange={e => {
                    setFieldValue('searchText', e.target.value);
                    (e.target.value.length >= 3 || e.target.value.length === 0) && handleSubmit();
                  }}
                  value={values.searchText}
                />
                <small className="form-text text-muted">
                  <b>{intl.formatMessage({ id: 'TABLE.FILTER.SEARCH' })}</b>{' '}
                  {intl.formatMessage({ id: 'TABLE.FILTER.IN_ALL_FIELDS' })}
                </small>
              </div>
              <div className="col-lg-2">
                <select
                  className="form-control"
                  name="status"
                  placeholder="Filter by Status"
                  onChange={e => {
                    setFieldValue('status', e.target.value);
                    handleSubmit();
                  }}
                  onBlur={handleBlur}
                  value={values.status}
                >
                  <option value="">Все</option>
                  <option value="0">Нет связи</option>
                  <option value="1">В сети</option>
                </select>
                <small className="form-text text-muted">
                  <b>{intl.formatMessage({ id: 'TABLE.FILTER' })}</b>{' '}
                  {intl.formatMessage({ id: 'TABLE.FILTER.BY_STATUS' })}
                </small>
              </div>
              <div className="col-lg-2">
                <select
                  className="form-control"
                  placeholder="Filter by Organization"
                  name="oid"
                  onBlur={handleBlur}
                  onChange={e => {
                    setFieldValue('oid', e.target.value);
                    handleSubmit();
                  }}
                  value={values.oid}
                >
                  <option value="">Все</option>
                  {orgEntities &&
                    orgEntities.map(org => {
                      return (
                        <option key={org.id} value={org.id}>
                          {org.name}
                        </option>
                      );
                    })}
                </select>
                <small className="form-text text-muted">
                  <b>{intl.formatMessage({ id: 'TABLE.FILTER' })}</b>{' '}
                  {intl.formatMessage({ id: 'TABLE.FILTER.BY_ORGANIZATION' })}
                </small>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </>
  );
};
