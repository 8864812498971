import { createSlice } from '@reduxjs/toolkit';

const initialPromotionState = {
    promotionLoading: false,
    promoOrderLoading: false,
    promotion: null,
    promotionEntities: [],
};
export const callTypes = {
    getPromotion: 'getPromotion',
    getPromoOrder: 'getPromoOrder',
};

export const promotionSlice = createSlice({
    name: 'promotion',
    initialState: initialPromotionState,
    reducers: {
        catchError: (state, action) => {
            state.error = `${action.type}: ${action.payload.error}`;
            if (action.payload.callType === callTypes.getPromotion) {
                state.promotionLoading = false;
            }

            if (action.payload.callType === callTypes.getPromoOrder) {
                state.promoOrderLoading = false;
            }
        },

        startCall: (state, action) => {
            state.error = null;
            if (action.payload.callType === callTypes.getPromotion) {
                state.promotionLoading = true;
            }

            if (action.payload.callType === callTypes.getPromoOrder) {
                state.promoOrderLoading = true;
            }
        },

        promotionFetched: (state, action) => {
            const { data } = action.payload;
            console.log(data.detail.data);
            state.promotionEntities = data.detail.data;
            state.promotion = data;
            state.promotionLoading = false;
            state.error = null;
        },

        promoOrderFetched: state => {
            state.promoOrderLoading = false;
            state.error = null;
        },
    },
});