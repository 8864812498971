import store from '../../../../redux/store';

const { REACT_APP_MODE, REACT_APP_REQ_PROD_API, REACT_APP_REQ_DEV_API, REACT_APP_API_VERSION } = process.env;
const ENDPOINT_API = REACT_APP_MODE === 'dev' ? REACT_APP_REQ_DEV_API : REACT_APP_REQ_PROD_API;

export const NOTIFICATION_URL = `${ENDPOINT_API}${REACT_APP_API_VERSION}/notifications?`;



export async function findUnreadNotifi(queryParams) {
    const token = store.getState().auth.authToken;
    const filter = JSON.stringify(queryParams.filter);

    return fetch(
        `${NOTIFICATION_URL}filter=${filter}&limit=${queryParams.limit}&sortField=${queryParams.sortField}&sortOrder=${queryParams.sortOrder}`, {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
        }
    ).then(res => res.json());
}