import React, { useMemo, useRef } from 'react';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';

import { validFormsData } from '../RegistrationUIHelpers';
import { useRegistrationUIContext } from '../RegistrationUIContext';

export const Step1 = () => {
  const formikRef = useRef(null);
  const registrationUIContext = useRegistrationUIContext();
  const registrationUIProps = useMemo(() => {
    return {
      validDataStep1: registrationUIContext.validDataStep1,
      setValidDataStep1: registrationUIContext.setValidDataStep1,
      formDataStep1: registrationUIContext.formDataStep1,
      setFormDataStep1: registrationUIContext.setFormDataStep1,
    };
  }, [
    registrationUIContext.validDataStep1,
    registrationUIContext.setValidDataStep1,
    registrationUIContext.formDataStep1,
    registrationUIContext.setFormDataStep1,
  ]);

  const Schema = Yup.object().shape({
    name: Yup.string().required('Поле обязательно для заполнения'),
    surname: Yup.string().required('Поле обязательно для заполнения'),
    patronymic: Yup.string().required('Поле обязательно для заполнения'),
  });

  const onValidate = e => {
    registrationUIProps.setFormDataStep1({ ...registrationUIProps.formDataStep1, [e.target.name]: e.target.value });
    formikRef.current.setFieldValue(e.target.name, e.target.value).then(() => {
      registrationUIProps.setValidDataStep1(formikRef.current.isValid);
      validFormsData.step1 = formikRef.current.isValid;
    });
  };

  return (
    <Formik
      innerRef={formikRef}
      initialValues={{
        name: registrationUIProps.formDataStep1.name,
        surname: registrationUIProps.formDataStep1.surname,
        patronymic: registrationUIProps.formDataStep1.patronymic,
      }}
      validationSchema={Schema}
    >
      {({ errors, touched }) => (
        <Form className="form form-label-right">
          <div className="form-group fv-plugins-icon-container has-danger">
            <label className="font-size-h6 font-weight-bolder text-dark">Ваша фамилия</label>
            <Field
              type="text"
              placeholder="Укажите вашу фамилию"
              name="name"
              onChange={e => onValidate(e)}
              className={`form-control form-control-solid h-auto py-5 px-6 mr-2
                  ${(touched.name && errors.name && 'is-invalid') || (touched.name && !errors.name && 'is-valid')}`}
            />
            {touched.name && errors.name ? <div className="invalid-feedback">{errors.name}</div> : null}
          </div>
          <div className="form-group fv-plugins-icon-container has-danger">
            <label className="font-size-h6 font-weight-bolder text-dark">Ваше имя</label>
            <Field
              type="text"
              placeholder="Укажите ваше имя"
              name="surname"
              onChange={e => onValidate(e)}
              className={`form-control form-control-solid h-auto py-5 px-6 mr-2
                  ${(touched.surname && errors.surname && 'is-invalid') ||
                    (touched.surname && !errors.surname && 'is-valid')}`}
            />
            {touched.surname && errors.surname ? <div className="invalid-feedback">{errors.surname}</div> : null}
          </div>
          <div className="form-group fv-plugins-icon-container has-danger">
            <label className="font-size-h6 font-weight-bolder text-dark">Ваше отчество</label>
            <Field
              type="text"
              placeholder="Укажите ваше отчество"
              name="patronymic"
              onChange={e => onValidate(e)}
              className={`form-control form-control-solid h-auto py-5 px-6 mr-2
                  ${(touched.patronymic && errors.patronymic && 'is-invalid') ||
                    (touched.patronymic && !errors.patronymic && 'is-valid')}`}
            />
            {touched.patronymic && errors.patronymic ? (
              <div className="invalid-feedback">{errors.patronymic}</div>
            ) : null}
          </div>
        </Form>
      )}
    </Formik>
  );
};
