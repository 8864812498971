import React from "react";
import { Redirect, Route, Switch } from "react-router";
import { ROUTES } from "../../Routes.models";
import { MyObjectsPage } from "./pages/myObjects/MyObjectsPage";

import { MyOrganisationsPage } from "./pages/myOrganisations/MyOrganisationsPage";
import UserProfilePage from "./pages/UserProfilePage";

export const UserInfoPage = () => {
  return (
    <Switch>
      <Redirect exact from={ROUTES.USER} to={ROUTES.USER_PROFILE} />
      <Route path={ROUTES.USER_PROFILE} component={UserProfilePage} />
      <Route path={ROUTES.USER_ORGANISATIONS} component={MyOrganisationsPage} />
      <Route path={ROUTES.USER_OBJECTS} component={MyObjectsPage} />
    </Switch>
  )
};