import React, { useState } from 'react';
import { useIntl } from "react-intl";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../../_metronic/_partials/controls";
import { MyOrganisationsFilter } from './myOrganisations-filter/MyOrganisationsFilter';
import { MyOrganisationsTable } from './myOrganisations-table/MyOrganisationsTable';
import { OrganisationCreateDialog } from './organisationCreateDialog/OrganisationCreateDialog';

export const MyOrganisationsCard = () => {
  const [showModal, setShowModal] = useState(false);
  const intl = useIntl();

  return (
    <>
      <OrganisationCreateDialog showModal={showModal} setShowModal={setShowModal} />
      <Card>
        <CardHeader title={intl.formatMessage({ id: "ORGANIZATIONS" })}>
          <CardHeaderToolbar>
            <button
              type="button"
              className="btn btn-primary"
              onClick={()=>setShowModal(true)}
            >
              Создать организацию
            </button>
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <MyOrganisationsFilter />
          <MyOrganisationsTable />
        </CardBody>
      </Card>
    </>
  );
};