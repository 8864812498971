export const stepName = ['Имя', 'Данные', 'Пароль'];

export const stepDescription = ['Ваше ФИО', 'Данные о вас', 'Пароль и условия'];

export const validFormsData = {
  step1: false,
  step2: false,
  step3: false,
};

export const initialFormsData = {
  step1: {
    name: '',
    surname: '',
    patronymic: '',
  },
  step2: {
    organization: '',
    phone: '',
    email: '',
    post: '',
    account_type: 'personal',
    suggestion: '',
  },
  step3: {
    password: '',
    password_confirmation: '',
    agree: '',
  },
};
