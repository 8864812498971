import store from '../../../../redux/store';

const { REACT_APP_MODE, REACT_APP_REQ_PROD_API, REACT_APP_REQ_DEV_API, REACT_APP_API_VERSION } = process.env;
const ENDPOINT_API = REACT_APP_MODE === 'dev' ? REACT_APP_REQ_DEV_API : REACT_APP_REQ_PROD_API;

const PROMOTION = `${ENDPOINT_API}${REACT_APP_API_VERSION}/promotions/`;
const PROMOTION_ORDER = `${ENDPOINT_API}${REACT_APP_API_VERSION}/promotions/order`;


export const fetchPromotion = async queryParams => {
    const token = store.getState().auth.authToken;

    return await fetch(
        `${PROMOTION}${queryParams.id}?include=detail`, {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
        }
    ).then(res => res.json());
};

export const fetchCreatePromoOrder = async queryParams => {
    const token = store.getState().auth.authToken;
    const formData = new FormData();

    for (let key in queryParams) {
        formData.append(key, queryParams[key]);
    }

    return await fetch(`${PROMOTION_ORDER}`, {
        method: 'POST',
        body: formData,
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }).then(result => result.json());
};