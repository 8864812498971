import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import 'yup-phone-lite';
import { useDispatch } from 'react-redux';
import SVG from 'react-inlinesvg';
import Swal from 'sweetalert2';

import * as action from '../_redux/authActions';
import { ROUTES } from '../../../Routes.models';
import { toAbsoluteUrl } from '../../../../_metronic/_helpers';

import './Auth.scss';
import { EndPoint } from './EndPoint';
const { REACT_APP_MODE, REACT_APP_REQ_DEV, REACT_APP_REQ_PROD } = process.env;
const ENDPOINT = REACT_APP_MODE === 'dev' ? REACT_APP_REQ_DEV : REACT_APP_REQ_PROD;
const PRIVACY = `${ENDPOINT}content/privacy_policy.html`;

const initialValues = {
  login: '',
};

export const Fast = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const LoginSchema = Yup.object().shape({
    login: Yup.string()
      .required('Поле обязательно для заполнения')
      .test('email_or_phone', 'Некорректный телефон или E-mail', value => {
        return validateEmail(value) || validatePhone(value);
      }),
  });

  const validateEmail = email => {
    return Yup.string()
      .email()
      .isValidSync(email);
  };

  const validatePhone = phone => {
    return Yup.string()
      .phone('RU')
      .isValidSync(phone);
  };

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  return (
    <div className="login-form login-signin" id="kt_login_signin_form">
      <Formik
        initialValues={initialValues}
        validationSchema={LoginSchema}
        validateOnChange={false}
        onSubmit={(values, { setStatus, setSubmitting }) => {
          enableLoading();
          dispatch(action.fetchGetCode(values))
            .then(() => {
              disableLoading();
              history.push(ROUTES.AUTH_CHECK);
            })
            .catch(e => {
              Swal.fire({
                title: 'Ошибка',
                text: e.message,
                icon: 'error',
                confirmButtonText: 'Продолжить',
                heightAuto: false,
              });
              setSubmitting(false);
              disableLoading();
            });
        }}
      >
        {({ errors, touched, isSubmitting, handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <div className="pb-10 pb-lg-10">
              <h3 className="font-weight-bolder text-dark font-size-h2 font-size-h1-lg">Вход</h3>
              <div className="text-muted font-weight-bold font-size-h4">
                Впервые у нас?&nbsp;
                <Link to={ROUTES.AUTH_REG} className="text-primary font-weight-bolder">
                  Создать аккаунт
                </Link>
              </div>
            </div>
            <EndPoint />
            <div className="form-group fv-plugins-icon-container has-danger">
              <label className="font-size-h6 font-weight-bolder text-dark">Телефон или E-mail</label>
              <Field
                type="text"
                placeholder="Введите телефон или E-mail"
                name="login"
                className={`form-control form-control-solid h-auto py-5 px-6 mr-2
                      ${(touched.login && errors.login && 'is-invalid') ||
                        (touched.login && !errors.login && 'is-valid')}`}
              />
              {touched.login && errors.login ? <div className="invalid-feedback">{errors.login}</div> : null}
            </div>
            <div className="text-left mb-5 mb-lg-5">
              <p className="font-weight-bold font-size-h8">
                Нажимая кнопку "Получить код", Вы соглашаетесь с условиями{' '}
                <a target="_blank" href={PRIVACY}>
                  политики конфиденциальности
                </a>
                .
              </p>
            </div>
            <div className="pb-lg-0 pb-5">
              <button
                type="submit"
                disabled={isSubmitting}
                className="btn btn-primary font-weight-bolder font-size-lg px-8 my-3 mr-3"
              >
                <span>Получить код</span>
                {loading && <span className="ml-3 spinner spinner-white"></span>}
              </button>
              <Link to={ROUTES.AUTH_LOGIN} className="btn btn-light-primary font-weight-bolder px-8 my-3 font-size-lg">
                <span className="svg-icon svg-icon-key svg-icon-md">
                  <SVG src={toAbsoluteUrl('/media/svg/icons/Home/KeyColor.svg')} />
                </span>
                Войти с паролем
              </Link>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};
