import * as requestFromServer from './ScadaCrud';
import { scadaSlice, callTypes } from './ScadaSlice';

const { actions } = scadaSlice;

export const getScadaList = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.getScadaList }));
  return requestFromServer
    .getScadaList(queryParams)
    .then(response => {
      const entities = response.data;
      const totalCount = response.meta.pagination.total;
      dispatch(actions.allScadaListFetched({ entities, totalCount }));
    })
    .catch(error => {
      error.clientMessage = "Can't get all dispatching objects!";
      dispatch(actions.catchError({ error, callType: callTypes.getScadaList }));
    });
};

export const getScadaShow = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.getScadaShow }));
  return requestFromServer
    .getScadaShow(queryParams)
    .then(response => {
      const { entity } = { entity: response.data };
      dispatch(actions.scadaShowFetched({ entity }));
    })
    .catch(error => {
      error.clientMessage = "Can't get dispatching object!";
      dispatch(actions.catchError({ error, callType: callTypes.getScadaShow }));
    });
};

export const getScadaShowVar = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.getScadaShowVar }));
  return requestFromServer
    .getScadaShowVar(queryParams)
    .then(response => {
      const { entities } = { entities: response.tags };
      dispatch(actions.scadaShowVarFetched({ entities }));
    })
    .catch(error => {
      error.clientMessage = "Can't get dispatching object variables!";
      dispatch(actions.catchError({ error, callType: callTypes.getScadaShowVar }));
    });
};
