import store from '../../../../redux/store';

const { REACT_APP_MODE, REACT_APP_REQ_PROD_API, REACT_APP_REQ_DEV_API, REACT_APP_API_VERSION } = process.env;
const ENDPOINT_API = REACT_APP_MODE === 'dev' ? REACT_APP_REQ_DEV_API : REACT_APP_REQ_PROD_API;

const PROMOTIONS = `${ENDPOINT_API}${REACT_APP_API_VERSION}/promotions?orderBy=id&sortedBy=desc`;

export const getPromotions = async queryParams => {
    const token = store.getState().auth.authToken;
    const formData = new FormData();

    for (let key in queryParams) {
        formData.append(key, queryParams[key]);
    }

    return await fetch(
        `${PROMOTIONS}`, {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
        }
    ).then(res => res.json());
};