import * as requestFromServer from './promotionCrud';

import { promotionSlice, callTypes } from './promotionSlice';

const { actions } = promotionSlice;

export const fetchPromotion = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.getPromotion }));
  return requestFromServer
    .fetchPromotion(queryParams)
    .then(response => {
      dispatch(actions.promotionFetched(response));
    })
    .catch(error => {
      error.clientMessage = "Can't find promotion!";
      dispatch(actions.catchError({ error, callType: callTypes.getPromotion }));
    });
};

export const fetchCreatePromoOrder = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.getPromoOrder }));
  return requestFromServer
    .fetchCreatePromoOrder(queryParams)
    .then(response => {
      dispatch(actions.promoOrderFetched(response));
      return response;
    })
    .catch(error => {
      error.clientMessage = "Can't create promo order!";
      dispatch(actions.catchError({ error, callType: callTypes.getPromoOrder }));
    });
};
