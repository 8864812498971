import React from 'react';
import store from '../../../../../../../redux/store';

const { REACT_APP_MODE, REACT_APP_REQ_DEV, REACT_APP_REQ_PROD } = process.env;
const ENDPOINT = REACT_APP_MODE === 'dev' ? REACT_APP_REQ_DEV : REACT_APP_REQ_PROD;

export const ScadaShowProject = ({ id }) => {
  const token = store.getState().auth.authToken;
  return (
    <div class="embed-responsive embed-responsive-16by9">
      <iframe
        src={`${ENDPOINT}scada/project/${id}?token=${token}`}
        name="btpIFrame"
        frameBorder="0"
        id="btpIFrame"
        className="embed-responsive-item"
        title="scada-project"
      />
    </div>
  );
};
