// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import YandexMaps from '../../../../../services/yandexMapsServices';
import * as actions from './../../../_redux/myObjects/myObjectsActions';
import { ModalInfo } from '../../../../../widgets/modalInfo/ModalInfo';
import { CardSpinner } from '../../../../../widgets/cardSpinner/CardSpinner';
import { initialFilter } from '../MyObjectsUIHelpers';

const ObjectEditSchema = Yup.object().shape({
  name: Yup.string()
    .required('Поле обязательно для заполнения'),
  address: Yup.string()
    .required('Выберите адрес из списка или поставьте метку на карте'),
  coords: Yup.string()
    .required('Установите метку на карте, чтобы получить координаты'),
});

let yandexMaps = null;

export function ObjectEditForm({ onHide }) {
  const [addressCoordinates, setAddressCoordinates] = useState('');
  const [inputHelp, setInputHelp] = useState([]);
  const [formInput, setFormInput] = useState('');
  const [showInputHelp, setShowInputHelp] = useState(false);
  const [showModalInfo, setShowModalInfo] = useState(false);
  const [modalInfoText, setModalInfoText] = useState('');
  const [modalInfoTitle, setModalInfoTitle] = useState('');

  const formikRef = useRef(null);
  const dispatch = useDispatch();

  const { currentState } = useSelector(
    (state) => ({ currentState: state.myObjects }),
    shallowEqual
  );

  const { objectSaving, modalData } = currentState;

  const setAddress = useCallback( addr => setFormInput(addr), []);
  const setCoordinates = useCallback(coords => setAddressCoordinates(coords), []);

  useEffect(() => {
    // eslint-disable-next-line no-undef
    yandexMaps = new YandexMaps({ ymaps, setAddress, setCoordinates });
    yandexMaps.ymaps.ready(() => {
      yandexMaps.init();
    });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (formInput.length < 3 || inputHelp.length === 0)
      setShowInputHelp(false);
  }, [formInput.length, inputHelp.length])

  useEffect(() => {
    if (!modalData) {
      return;
    };

    setModalInfoText(modalData.text);
    setModalInfoTitle(modalData.title);
    setShowModalInfo(true);
  }, [modalData]);

  useEffect(() => {
    formikRef.current.setFieldValue('coords', addressCoordinates);
  }, [addressCoordinates]);

  useEffect(() => {
    formikRef.current.setFieldValue('address', formInput);
  }, [formInput]);

  const saveObject = useCallback(values => {
    dispatch(actions.fetchSaveMyObjects(values))
      .then(() => dispatch(actions.fetchMyObjects(initialFilter)));
  }, [dispatch]);

  const searchAddressYmaps = useCallback((val) => {
    yandexMaps.ymaps.suggest(val, { results: 5 })
      .then((items) => setInputHelp(items.map(item => item.value)));
  }, []);

  const handleAddressChange = useCallback((e) => {
    formikRef.current.setFieldValue('coords', '');
    setFormInput(e.target.value);

    if (e.target.value.length > 2) {
      searchAddressYmaps(e.target.value);

      setShowInputHelp(true);
    };

  }, [searchAddressYmaps]);

  const handleInputHelpClick = useCallback(async(e) => {
    setFormInput(e.target.value);
    setShowInputHelp(false);
    yandexMaps.createPlacemark(e.target.value);

  }, []);

  const modalInfoOnOff = useCallback((trigger) => {
    if (!trigger) {
      setShowModalInfo(false);
      setFormInput('');
      onHide();
      dispatch(actions.fetchClearSaveResponse());
    }
  }, [dispatch, onHide]);

  return (
    <>
      <ModalInfo showModal={showModalInfo} setShowModal={modalInfoOnOff} title={modalInfoTitle} text={modalInfoText} />
      <Formik
        innerRef={formikRef}
        initialValues={{
          address: '',
          name: '',
          coords: '',
        }}
        validationSchema={ObjectEditSchema}
        onSubmit={values => {
          saveObject(values);
        }}
      >
        {({ handleSubmit, errors, touched, isValid }) => (
          <>
            <Modal.Body className="overlay overlay-block cursor-default">
              {objectSaving && <CardSpinner />}
              <Form className="form form-label-right" onSubmit={handleSubmit}>
                <div className="form-group row">
                  <div className="col-12">
                    <label className="col-form-label text-alert">Имя объекта</label>
                    <Field
                      name="name"
                      className={`form-control form-control-lg form-control-solid ${touched.name && errors.name && "is-invalid"}`}
                      autoComplete="nope"
                      placeholder="Имя объекта"
                    />
                    {(errors.name && touched.name) && <div className="invalid-feedback">{errors.name}</div>}
                  </div>
                  <div className="d-none">
                    <label className="col-12 col-form-label text-alert">Координаты</label>
                    <Field
                      name="coords"
                      className={`form-control form-control-lg form-control-solid ${touched.coords && errors.coords && "is-invalid"}`}
                      placeholder="Координаты объекта"
                      readOnly={true}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <div className="row">
                    <div className="col-12 position-relative">
                      <label className="col-form-label text-alert">Адрес объекта</label>
                      <Field
                        name="address"
                        id="address"
                        className={`form-control form-control-lg form-control-solid ${touched.address && errors.address && "is-invalid"}`}
                        placeholder="Адрес объекта"
                        value={formInput}
                        onChange={(e)=>handleAddressChange(e)}
                        autoComplete="nope"
                      />
                      {(errors.address && touched.address) && <div className="invalid-feedback">{errors.address}</div>}
                      {(errors.coords && touched.coords) && <div className="invalid-feedback">{errors.coords}</div>}
                      <select
                        className={`custom-select position-absolute z-10 ${showInputHelp ? 'd-block' : 'd-none'}`}
                        size={inputHelp.length <= 1 ? inputHelp.length + 1 : inputHelp.length - 0}
                      >
                        {inputHelp.map(helpItem => (
                          <option key={helpItem} onClick={e => handleInputHelpClick(e)}>
                            {helpItem}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-12">
                    <div id="map"></div>
                  </div>
                </div>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <button type="button" onClick={onHide} className="btn btn-light btn-elevate">
                Закрыть
              </button>
              <button
                type="button"
                onClick={() => handleSubmit()}
                className="btn btn-primary btn-elevate"
                disabled={objectSaving || !isValid}
              >
                Создать объект
              </button>
            </Modal.Footer>
          </>
        )}
      </Formik>
    </>
  );
}
