/* eslint-disable no-undef */
/* eslint-disable no-unused-expressions */
import React, { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import * as actions from './_redux/homeActions';
import * as promosActions from './../Promotions/redux/promotionsActions';
import * as notifiActions from './../Notifications/_redux/notificationsActions';
import { ROUTES } from '../../Routes.models';
import {
  ListsWidget9,
  TilesWidget3,
  TilesWidget10,
  TilesWidget11,
  TilesWidget12,
  TilesWidget13,
  TilesWidget14,
} from './../../../_metronic/_partials/widgets';
import { CardSpinner } from '../../widgets/cardSpinner/CardSpinner';
import { NotifiWidget } from '../../widgets/NotifiWidget/NotifiWidget';
import { NewsWidget } from '../../widgets/newsWidgets/NewsWidget';
import { PromoWidget } from '../../widgets/PromoWidget/PromoWidget';

export const HomeDashboard = () => {
  const { currentStateHome, currentStateNotify, currentStatePromos } = useSelector(
    state => ({
      currentStateHome: state.home,
      currentStateNotify: state.notifications,
      currentStatePromos: state.promotions,
    }),
    shallowEqual,
  );

  const { homeEntities } = { homeEntities: currentStateHome.entities };
  const { notifiEntities } = { notifiEntities: currentStateNotify.entities };
  const promotionsEntities = currentStatePromos.promotionsEntities;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.fetchDashboardData());
    dispatch(notifiActions.fetchLastNotifications());
    dispatch(promosActions.fetchPromotions());
  }, [dispatch]);

  return homeEntities && notifiEntities && promotionsEntities ? (
    <>
      <div className="row">
        <div className="col-xl-4">
          <ListsWidget9
            className="gutter-b card-stretch"
            chartColor="danger"
            changeLog={homeEntities.changeLog}
            version={homeEntities.version}
          />
        </div>
        <div className="col-xl-8">
          <div className="row">
            <div className="col-12 col-sm-4 col-md-3">
              <TilesWidget3 className="gutter-b" widgetHeight="150px" title="Заказы" href={ROUTES.ORDERS} />
            </div>
            <div className="col-12 col-sm-8 col-md-9">
              <TilesWidget10
                className="gutter-b"
                widgetHeight="150px"
                title="Как пользоваться?"
                discription="Узнайте как пользоваться личным кабинетом"
                btnTitle={'Подробнее'}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-xl-6">
              <TilesWidget13
                className="gutter-b"
                widgetHeight="175px"
                row1="Рассчитать насосное"
                row2="оборудование"
                btnTitle="Рассчитать"
                href={ROUTES.NEW_PUMP}
              />
              <div className="row">
                <div className="col-sm-6">
                  <TilesWidget11
                    className="gutter-b"
                    baseColor="primary"
                    widgetHeight="150px"
                    counter={homeEntities.request_count}
                    text="Расчетов"
                    href={ROUTES.CALCULATIONS}
                  />
                </div>
                <div className="col-sm-6">
                  <TilesWidget12
                    className="gutter-b"
                    iconColor="success"
                    widgetHeight="150px"
                    counter={homeEntities.sales_count}
                    text="Заказов"
                    href={ROUTES.ORDERS}
                  />
                </div>
              </div>
            </div>

            <div className="col-xl-6">
              <TilesWidget14
                className="gutter-b card-stretch"
                row1="Рассчитать"
                row2="теплообменник"
                titleBtn="Перейти к расчету"
                href={ROUTES.NEW_TO}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-md-5">
          <NotifiWidget className="gutter-b" title="Уведомления" entities={notifiEntities} />
          <PromoWidget title="Акции" entities={promotionsEntities} />
          {console.log(promotionsEntities)}
        </div>
        <div className="col-12 col-md-7">
          <NewsWidget news={homeEntities.news} />
        </div>
      </div>
    </>
  ) : (
    <CardSpinner />
  );
};
