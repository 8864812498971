import React, { useCallback, useState } from 'react';
import { Field, Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { Link, Redirect } from 'react-router-dom';
import * as Yup from 'yup';
import { recovery } from '../_redux/authCrud';
import { ROUTES } from '../../../Routes.models';
import SVG from 'react-inlinesvg';
import { toAbsoluteUrl } from '../../../../_metronic/_helpers';

import * as action from './../_redux/authActions';
const { REACT_APP_MODE, REACT_APP_REQ_DEV, REACT_APP_REQ_PROD } = process.env;
const ENDPOINT = REACT_APP_MODE === 'dev' ? REACT_APP_REQ_DEV : REACT_APP_REQ_PROD;
const PRIVACY = `${ENDPOINT}content/privacy_policy.html`;

export const ForgotPassword = () => {
  const intl = useIntl();
  const [isRequested, setIsRequested] = useState(false);
  const initialValues = {
    login: '',
  };
  const dispatch = useDispatch();
  const Schema = Yup.object().shape({
    login: Yup.string()
      .required('Поле обязательно для заполнения')
      .test('email_or_phone', 'Некорректный телефон или E-mail', value => {
        return validateEmail(value) || validatePhone(value);
      }),
  });

  const validateEmail = email => {
    return Yup.string()
      .email()
      .isValidSync(email);
  };

  const validatePhone = phone => {
    return Yup.string()
      .phone('RU')
      .isValidSync(phone);
  };

  const [loading, setLoading] = useState(false);

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  return (
    <div className="login-form login-signin" id="kt_login_signin_form">
      <Formik
        initialValues={initialValues}
        validationSchema={Schema}
        validateOnChange={false}
        onSubmit={(values, { setFieldValue, setStatus, setSubmitting }) => {
          setSubmitting(true);
          enableLoading();
          dispatch(action.fetchRecovery(values))
            .then(response => {
              disableLoading();
              setStatus({
                type: 'success',
                message: response.message,
              });
            })
            .catch(e => {
              disableLoading();
              setSubmitting(false);
              setStatus({
                type: 'danger',
                message: e.message,
              });
            });
        }}
      >
        {({ errors, status, touched, isSubmitting, handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <div className="pb-10 pb-lg-10">
              <h3 className="font-weight-bolder text-dark font-size-h2 font-size-h1-lg">Восстановление пароля</h3>
              <div className="text-muted font-weight-bold font-size-h4">
                Впервые у нас?&nbsp;
                <Link to={ROUTES.AUTH_REG} className="text-primary font-weight-bolder">
                  Создать аккаунт
                </Link>
              </div>
            </div>
            {status ? (
              <div className={`mb-10 alert alert-custom alert-light-${status.type} alert-dismissible`}>
                <div className="alert-text font-weight-bold">{status.message}</div>
              </div>
            ) : null}
            <div className="form-group fv-plugins-icon-container has-danger">
              <label className="font-size-h6 font-weight-bolder text-dark">Телефон или E-mail</label>
              <Field
                type="text"
                placeholder="Введите телефон или E-mail"
                name="login"
                className={`form-control form-control-solid h-auto py-5 px-6 mr-2
                      ${(touched.login && errors.login && 'is-invalid') ||
                        (touched.login && !errors.login && 'is-valid')}`}
              />
              {touched.login && errors.login ? <div className="invalid-feedback">{errors.login}</div> : null}
            </div>
            <div className="text-left mb-5 mb-lg-5">
              <p className="font-weight-bold font-size-h8">
                Нажимая кнопку "Восстановить", Вы соглашаетесь с условиями{' '}
                <a target="_blank" href={PRIVACY}>
                  политики конфиденциальности
                </a>
                .
              </p>
            </div>
            <div className="pb-lg-0 pb-5">
              {status && status.type == 'success' ? (
                <Link
                  to={ROUTES.AUTH_LOGIN}
                  className="btn btn-light-primary font-weight-bolder px-8 my-3 font-size-lg"
                >
                  Вернуться к авторизации
                </Link>
              ) : (
                <>
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className="btn btn-primary font-weight-bolder font-size-lg px-8 my-3 mr-3"
                  >
                    <span>Восстановить</span>
                    {loading && <span className="ml-3 spinner spinner-white"></span>}
                  </button>
                  <Link
                    to={ROUTES.AUTH_LOGIN}
                    className="btn btn-light-primary font-weight-bolder px-8 my-3 font-size-lg"
                  >
                    Отмена
                  </Link>
                </>
              )}
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

//   return (
//     <Formik
//       initialValues={initialValues}
//       validationSchema={Schema}
//       validateOnChange={false}
//       onSubmit={(values, { setSubmitting, resetForm }) => {
//         recoveryPass(values, setSubmitting, resetForm);
//       }}
//     >
//       {({ errors, status, touched, isSubmitting, handleSubmit }) => (
//         <>
//           {isRequested && <Redirect to={ROUTES.AUTH} />}
//           {!isRequested && (
//              <Form className="calculationTOForm" onSubmit={handleSubmit}>
//             <div className="pb-10 pb-lg-10">
//               <h3 className="font-weight-bolder text-dark font-size-h2 font-size-h1-lg">Вход</h3>
//               <div className="text-muted font-weight-bold font-size-h4">
//                 Впервые у нас?&nbsp;
//                 <Link to={ROUTES.AUTH_REG} className="text-primary font-weight-bolder">
//                   Создать аккаунт
//                 </Link>
//               </div>
//             </div>
//                 {status && (
//                   <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
//                     <div className="alert-text font-weight-bold">{status}</div>
//                   </div>
//                 )}
//                 <div className="form-group fv-plugins-icon-container has-danger">
//                   <label className="font-size-h6 font-weight-bolder text-dark">Телефон или E-mail</label>
//                   <Field
//                     type="text"
//                     placeholder="Введите телефон или E-mail"
//                     name="login"
//                     className={`form-control form-control-solid h-auto py-5 px-6 mr-2
//                       ${(touched.login && errors.login && 'is-invalid') ||
//                         (touched.login && !errors.login && 'is-valid')}`}
//                   />
//                   {touched.login && errors.login ? <div className="invalid-feedback">{errors.login}</div> : null}
//                 </div>
//                 <div className="text-left mb-5 mb-lg-5">
//                   <p className="font-weight-bold font-size-h8">
//                     Нажимая кнопку "Получить код", Вы соглашаетесь с условиями{' '}
//                     <a target="_blank" href={PRIVACY}>
//                       политики конфиденциальности
//                     </a>
//                     .
//                   </p>
//                 </div>
//                 <div className="form-group d-flex flex-wrap flex-center">
//                   <button
//                     id="kt_login_forgot_submit"
//                     type="submit"
//                     className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
//                     disabled={isSubmitting}
//                   >
//                     Отправить
//                   </button>
//                   <Link to={ROUTES.AUTH}>
//                     <button
//                       type="button"
//                       id="kt_login_forgot_cancel"
//                       className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
//                     >
//                       Отмена
//                     </button>
//                   </Link>
//                 </div>
//               </form>
//             </div>
//           )}
//         </>
//       )}
//     </Formik>
//   );
// };
