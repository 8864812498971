import React from 'react';
import { Step4Table } from './Step4Table/Step4Table';

export const Step4 = () => {
  return (
    <>
      <div className="pb-5" data-wizard-type="step-content" data-wizard-state="current">
        <h3 className="mb-10 font-weight-bold text-dark">Выбор товаров</h3>
        <div className="separator separator-dashed my-5"></div>
        <div className="form-group">
          <label className="pb-4">Выберите тип документа</label>
          <Step4Table />
        </div>
      </div>
    </>
  );
};