import React, {createContext, useContext, useState, useCallback} from "react";
import {isEqual, isFunction} from "lodash";
import { initialFilter } from "./NotificationsUIHelpers";

const NotificationsUIContext = createContext();

export function useNotificationsUIContext() {
  return useContext(NotificationsUIContext);
}

export const NotificationsUIConsumer = NotificationsUIContext.Consumer;

export function NotificationsUIProvider({notificationsUIEvents, children}) {
  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const [ids, setIds] = useState([]);
  const setQueryParams = useCallback(nextQueryParams => {
    setQueryParamsBase(prevQueryParams => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);

  const initNotification= {
    id: undefined,
    date: '',
    icon: '',
    module: '',
    status: '',
    title: '',
    pid: '',
    text: '',
    url: '',
    read_date: '',
  };

  const value = {
    queryParams,
    setQueryParamsBase,
    ids,
    setIds,
    setQueryParams,
    initNotification,
    /* newCalculationButtonClick: notificationsUIEvents.newCalculationButtonClick,
    openEditCalculationDialog: notificationsUIEvents.openEditCalculationDialog,
    openDeleteCalculationDialog: notificationsUIEvents.openDeleteCalculationDialog,
    openDeleteNotificationsDialog: notificationsUIEvents.openDeleteNotificationsDialog,
    openFetchNotificationsDialog: notificationsUIEvents.openFetchNotificationsDialog,
    openUpdateNotificationsStatusDialog: notificationsUIEvents.openUpdateNotificationsStatusDialog */
  };

  return <NotificationsUIContext.Provider value={value}>{children}</NotificationsUIContext.Provider>;
}