import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Form, Modal } from 'react-bootstrap';
import { Field, Formik } from 'formik';
import * as Yup from 'yup';

import * as actions from '../../../_redux/myOrganisations/myOrganisationsActions';
import { daDataServices } from '../../../../../services/daDataServices';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { ModalInfo } from '../../../../../widgets/modalInfo/ModalInfo';
import { initialFilter } from '../MyOrganisationsUIHelpers';
import { PartySuggestions } from 'react-dadata';

const OrganisationCreateSchema = Yup.object().shape({
  inn: Yup.string().required('Вберите организацию'),
  post: Yup.string().required('Укажите должность, например "Представитель"'),
});

export const OrganisationCreateDialog = ({ showModal, setShowModal }) => {
  const [showInputHelp, setisShowInputHelp] = useState(false);
  const [formInput, setFormInput] = useState('');
  const [InputHelp, setInputHelp] = useState([]);
  const [lastClickVal, setLastClickVal] = useState('');
  const [showModalInfo, setShowModalInfo] = useState(false);
  const [modalInfoText, setModalInfoText] = useState('');
  const [modalInfoTitle, setModalInfoTitle] = useState('');

  const dispatch = useDispatch();
  const formikRef = useRef(null);

  const { currentState } = useSelector(state => ({ currentState: state.myOrganisations }), shallowEqual);

  const { modalData, orgSaving } = currentState;

  useEffect(() => {
    if (!modalData) {
      return;
    }

    setModalInfoText(modalData.text);
    setModalInfoTitle(modalData.title);
    setShowModalInfo(true);
    setShowModal(false);
  }, [modalData, setShowModal]);

  const sendForm = useCallback(
    values => {
      dispatch(actions.fetchSaveMyOrganisations(values)).then(() =>
        dispatch(actions.fetchMyOrganisations(initialFilter)),
      );
    },
    [dispatch],
  );

  const modalInfoOnOff = useCallback(
    trigger => {
      if (!trigger) {
        setShowModalInfo(false);
        dispatch(actions.fetchClearSaveResponse());
        setFormInput('');
      }
    },
    [dispatch],
  );

  const onDaData = e => {
    formikRef.current.setFieldValue('inn', e.data.inn);
  };

  return (
    <>
      <ModalInfo showModal={showModalInfo} setShowModal={modalInfoOnOff} title={modalInfoTitle} text={modalInfoText} />
      <Modal dialogClassName="" show={showModal} onHide={setShowModal} animation={true}>
        <Modal.Header>
          <Modal.Title>Новая организация</Modal.Title>
        </Modal.Header>
        <Formik
          innerRef={formikRef}
          initialValues={{
            inn: '',
            post: '',
          }}
          validationSchema={OrganisationCreateSchema}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            sendForm({ ...values });
          }}
        >
          {({ errors, touched, isSubmitting, isValid, handleSubmit }) => (
            <Form className="orm form-label-right" onSubmit={handleSubmit}>
              <Modal.Body>
                <div className="form-group row">
                  <label className="col-12 col-form-label text-alert">Выберите организацию:</label>
                  <div className="col-12">
                    <div className="position-relative">
                      <PartySuggestions
                        token="ddeafb9ff43e79578243d8f031f6e9d8b047ca0e"
                        count="3"
                        onChange={e => onDaData(e)}
                        inputProps={{
                          className: `form-control form-control-lg form-control-solid
                        ${(touched.inn && errors.inn && 'is-invalid') || (touched.inn && !errors.inn && 'is-valid')}`,
                          placeholder: `Начните вводить ИНН или название организации`,
                        }}
                      />

                      <Field type="hidden" name="inn" className={`${errors.inn && touched.inn && 'is-invalid'}`} />
                      {errors.inn ? <div className="invalid-feedback">{errors.inn}</div> : null}
                    </div>
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-12 col-form-label text-alert">Ваша должность</label>
                  <div className="col-12">
                    <Field
                      name="post"
                      className={`form-control form-control-lg form-control-solid ${errors.post &&
                        touched.post &&
                        'is-invalid'}`}
                      placeholder="Ваша должность"
                    />
                    {touched.post && errors.post ? <div className="invalid-feedback">{errors.post}</div> : null}
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button className="btn btn-secondary" onClick={() => setShowModal(false)} variant="primary">
                  Закрыть
                </button>
                <button
                  className="btn btn-primary"
                  onClick={() => formikRef.current.handleSubmit()}
                  disabled={orgSaving}
                >
                  Создать организацию
                </button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};
