import store from '../../../../redux/store';

const {
  REACT_APP_MODE,
  REACT_APP_REQ_PROD_API,
  REACT_APP_REQ_DEV_API,
  REACT_APP_API_VERSION,
} = process.env;
const ENDPOINT_API = REACT_APP_MODE === 'dev' ? REACT_APP_REQ_DEV_API : REACT_APP_REQ_PROD_API;

const SUPPORT_GET = `${ENDPOINT_API}${REACT_APP_API_VERSION}/support`;
const SUPPORT_SHOW = `${ENDPOINT_API}${REACT_APP_API_VERSION}/support`;

const SUPPORT_STORE = `${ENDPOINT_API}${REACT_APP_API_VERSION}/support`;
const SUPPORT_CREATE_MSGS = `${ENDPOINT_API}${REACT_APP_API_VERSION}/support/message`;
const SUPPORT_CREATE_FILE = `${ENDPOINT_API}${REACT_APP_API_VERSION}/support/media`;

//отправка файла
export const createFileReports = async queryParams => {
  const token = store.getState().auth.authToken;
  const formData = new FormData();

  for (let key in queryParams) {
    formData.append(key, queryParams[key]);
  }

  const response = fetch(`${SUPPORT_CREATE_FILE}`, {
    method: 'POST',
    body: formData,
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  }).then(result => result.json());

  return await response;
};

//Создание сообщения
export const createMsgsReports = async queryParams => {
  const token = store.getState().auth.authToken;
  const formData = new FormData();
  for (let key in queryParams) {
    formData.append(key, queryParams[key]);
  }
  const response = fetch(`${SUPPORT_CREATE_MSGS}`, {
    method: 'POST',
    body: formData,
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  }).then(result => result.json());
  return await response;
};

//получение всех заявок
export const getReport = async queryParams => {
  const token = store.getState().auth.authToken;
  const response = fetch(`${SUPPORT_GET}`, {
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  }).then(result => result.json());
  return await response;
};

//Создание заявки
export const createReport = queryParams => {
  const token = store.getState().auth.authToken;
  const formData = new FormData();
  for (let key in queryParams) {
    formData.append(key, queryParams[key]);
  }
  const response = fetch(`${SUPPORT_STORE}`, {
    method: 'POST',
    body: formData,
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  }).then(result => result.json());
  return response;
};

//просмотр заявки
export const showSupMsgsReports = async queryParams => {
  const token = store.getState().auth.authToken;
  const formData = new FormData();
  let id;
  for (let key in queryParams) {
    id = queryParams[key];
  }
  const response = fetch(`${SUPPORT_SHOW}/${id}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  }).then(result => result.json());
  return await response;
};
