import React, { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useIntl } from "react-intl";

import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../../_metronic/_partials/controls";
import { MyObjectsTable } from './myObjects-table/MyObjectsTable';
import { MyObjectsFilter } from './myObjects-filter/MyObjectsFilter';
import { useMyObjectsUIContext } from './MyObjectsUIContext';
import * as actions from './../../_redux/myObjects/myObjectsActions';

export const MyObjectsCard = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const objectsUIContext = useMyObjectsUIContext();
  const objectsUIProps = useMemo(() => {
    return {
      ids: objectsUIContext.ids,
      newObjectButtonClick: objectsUIContext.newObjectButtonClick,
      objectsMapButtonClick: objectsUIContext.objectsMapButtonClick,
      queryParams: objectsUIContext.queryParams
    };
  }, [objectsUIContext.ids, objectsUIContext.newObjectButtonClick, objectsUIContext.objectsMapButtonClick, objectsUIContext.queryParams]);

  useEffect(() => {
    dispatch(actions.fetchMyObjects(objectsUIProps.queryParams));
  }, [dispatch, objectsUIProps.queryParams]);

  useEffect(() => {
    dispatch(actions.getAllMyObjects());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Card>
        <CardHeader title={intl.formatMessage({ id: "OBJECTS" })}>
          <CardHeaderToolbar>
            <button
              type="button"
              className="btn btn-primary mr-2"
              onClick={objectsUIProps.objectsMapButtonClick}
            >
              Карта объектов
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={objectsUIProps.newObjectButtonClick}
            >
              Создать объект
            </button>
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <MyObjectsFilter />
          <MyObjectsTable />
        </CardBody>
      </Card>
    </>
  );
};