import { createSlice } from '@reduxjs/toolkit';

const initialCalculationFlowMeterState = {
  calcLoading: false,
  regionsLoading: false,
  citiesLoading: false,
  heatOrgsLoading: false,
  regions: [],
  cities: [],
  heatOrgs: [],
  error: null,
};

export const callTypes = {
  calc: 'calc',
  regions: 'regions',
  cities: 'cities',
  heatOrgs: 'heatOrgs',
};

export const calculationFlowMeterSlice = createSlice({
  name: 'calculationFlowMeter',
  initialState: initialCalculationFlowMeterState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.calc) {
        state.calcLoading = false;
        return;
      }

      if (action.payload.callType === callTypes.regions) {
        state.regionsLoading = false;
        return;
      }

      if (action.payload.callType === callTypes.cities) {
        state.citiesLoading = false;
        return;
      }

      if (action.payload.callType === callTypes.heatOrgs) {
        state.heatOrgsLoading = false;
        return;
      }
    },

    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.calc) {
        state.calcLoading = true;
        return;
      }

      if (action.payload.callType === callTypes.regions) {
        state.regionsLoading = true;
        return;
      }

      if (action.payload.callType === callTypes.cities) {
        state.citiesLoading = true;
        return;
      }

      if (action.payload.callType === callTypes.heatOrgs) {
        state.heatOrgsLoading = true;
        return;
      }
    },

    calcFetched: state => {
      state.calcLoading = false;
      state.error = null;
    },

    regionsFetched: (state, action) => {
      const { data } = action.payload;
      state.regions = data.entity;
      state.regionsLoading = false;
      state.error = null;
    },

    citiesFetched: (state, action) => {
      const { data } = action.payload;
      state.cities = data.entity;
      state.citiesLoading = false;
      state.error = null;
    },

    heatOrgsFetched: (state, action) => {
      const { data } = action.payload;
      state.heatOrgs = data.entity;
      state.heatOrgsLoading = false;
      state.error = null;
    },
  },
});
