import React from 'react';
import store from '../../../../../../../redux/store';

const { REACT_APP_MODE, REACT_APP_REQ_DEV, REACT_APP_REQ_PROD } = process.env;
const ENDPOINT = REACT_APP_MODE === 'dev' ? REACT_APP_REQ_DEV : REACT_APP_REQ_PROD;

export const ScadaShowChart = ({ id }) => {
  const token = store.getState().auth.authToken;
  return (
    <iframe
      src={`${ENDPOINT}scada/chart/${id}?token=${token}`}
      name="btpIFrame"
      frameBorder="0"
      id="btpIFrame"
      width="100%"
      height="900"
      title="scada_chart"
    />
  );
};
