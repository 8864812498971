import { createSlice } from '@reduxjs/toolkit';

const initialPumpState = {
    pumpListLoading: false,
    pumpCalculationSaving: false,
    entities: null,
    pumpResult: null,
    pumpFormValues: null,
    saveResponseType: null,
    attachId: null,
    saveCalculationNum: null,
    manufactureLoading: false,
    modelsLoading: false,
    manufacture: [],
    models: [],
};

export const callTypes = {
    list: 'list',
    saveCalc: 'saveCalc',
    manufacture: 'manufacture',
    models: 'models',
};

export const calculationPumpSlice = createSlice({
    name: 'calculationPump',
    initialState: initialPumpState,
    reducers: {
        catchError: (state, action) => {
            state.error = `${action.type}: ${action.payload.error}`;
            if (action.payload.callType === callTypes.list) {
                state.pumpListLoading = false;
                return;
            }
            if (action.payload.callType === callTypes.saveCalc) {
                state.pumpCalculationSaving = false;
                return;
            }
            if (action.payload.callType === callTypes.manufacture) {
                state.manufactureLoading = false;
                return;
            }
            if (action.payload.callType === callTypes.models) {
                state.modelsLoading = false;
                return;
            }
        },

        startCall: (state, action) => {
            state.error = null;
            state.attachId = null;
            state.saveCalculationNum = null;
            state.saveResponseType = null;
            state.responseType = null;

            if (action.payload.callType === callTypes.list) {
                state.pumpListLoading = true;
                return;
            }

            if (action.payload.callType === callTypes.saveCalc) {
                state.pumpCalculationSaving = true;
                return;
            }

            if (action.payload.callType === callTypes.manufacture) {
                state.manufactureLoading = true;
                return;
            }

            if (action.payload.callType === callTypes.models) {
                state.modelsLoading = true;
                return;
            }

        },

        pumpCalculationFetched: (state, action) => {
            const { data, queryParams } = action.payload;
            state.pumpListLoading = false;
            state.error = null;
            state.entities = data.entities;
            state.entities_links = {};
            data.entities.forEach((item, index) => {
                state.entities_links[item.cid] = index;
            })
            state.pumpResult = data.type;
            state.pumpFormValues = queryParams;
        },

        savePumpCalculationFetched: (state, action) => {
            const { data } = action.payload;
            state.pumpCalculationSaving = false;
            state.error = null;
            if (data.type === 'success') {
                state.attachId = data.aid;
                state.saveResponseType = data.type;
                state.saveCalculationNum = data.request;
                return;
            }
        },

        clearSaveResponseType: state => {
            state.saveResponseType = initialPumpState.saveResponseType;
            state.attachId = initialPumpState.attachId;
            state.saveCalculationNum = initialPumpState.saveCalculationNum;
        },

        manufactureFetched: (state, action) => {         
            state.manufacture = action.payload.manufacture;
            state.manufactureLoading = false;
            state.error = null;
        },
        modelsFetched: (state, action) => {           
            state.models = action.payload.models;
            state.modelsLoading = false;
            state.error = null;
        },
    },
});