import * as requestFromServer from './scannerCrud';
import { findAllOrders } from '../../../ECommerce/_redux/orders/ordersCrud';

import { scannerSlice, callTypes } from './scannerSlice';

const { actions } = scannerSlice;

export const fetchAllOrders = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return findAllOrders(queryParams)
    .then(response => {
      const { entities } = response.data;
      dispatch(actions.allOrdersFetched({ entities }));
    })
    .catch(error => {
      error.clientMessage = "Can't find all orders!";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchScanSend = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.scan }));
  return requestFromServer
    .scanSend(queryParams)
    .then(response => {
      dispatch(actions.scanFetched(response));
      return response;
    })
    .catch(error => {
      error.clientMessage = "Can't find scan!";
      dispatch(actions.catchError({ error, callType: callTypes.scan }));
    });
};

export const fetchGetScanFile = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.getScanFile }));
  return requestFromServer
    .getScanFile(queryParams)
    .then(response => {
      dispatch(actions.getScanFetched(response));
    })
    .catch(error => {
      error.clientMessage = "Can't get ScanFile!";
      dispatch(actions.catchError({ error, callType: callTypes.getScanFile }));
    });
};

export const fetchCheckedScanPages = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.checkedScan }));
  return requestFromServer
    .scanCheckedPagesSend(queryParams)
    .then(response => {
      dispatch(actions.checkedScanFetched(response));
    })
    .catch(error => {
      error.clientMessage = "Can't find scan!";
      dispatch(actions.catchError({ error, callType: callTypes.checkedScan }));
    });
};

export const fetchGetProductByCID = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.getProduct }));
  return requestFromServer
    .getProductByCID(queryParams)
    .then(response => {
      dispatch(actions.getProductByCIDFetched());
      return { ...queryParams, ...response.catalog };
    })
    .catch(error => {
      error.clientMessage = "Can't get product!";
      dispatch(actions.catchError({ error, callType: callTypes.getProduct }));
    });
};

export const changeScanList = data => dispatch => {
  return dispatch(actions.setScanList(data));
};

export const sendSelectedProducts = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.sendProduct }));
  return requestFromServer
    .sendSelectedProducts(queryParams)
    .then(response => {
      dispatch(actions.sendSelectedProductsFetched());
    })
    .catch(error => {
      error.clientMessage = "Can't get product!";
      dispatch(actions.catchError({ error, callType: callTypes.sendProduct }));
    });
};
