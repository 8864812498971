import React, { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';

import * as action from './../_redux/authActions';
import { useEffect } from 'react';
import { ROUTES } from '../../../Routes.models';
import { Link, useHistory } from 'react-router-dom';

import SVG from 'react-inlinesvg';
import Swal from 'sweetalert2';
import { toAbsoluteUrl } from '../../../../_metronic/_helpers';
import { EndPoint } from './EndPoint';
const { REACT_APP_MODE, REACT_APP_REQ_DEV, REACT_APP_REQ_PROD } = process.env;
const ENDPOINT = REACT_APP_MODE === 'dev' ? REACT_APP_REQ_DEV : REACT_APP_REQ_PROD;
const PRIVACY = `${ENDPOINT}content/privacy_policy.html`;

const initialValues = {
  login: '',
  password: '',
};

const Login = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const intl = useIntl();
  const [passInputType, setPassInputType] = useState('password');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    dispatch(action.logout());
  }, []);

  const LoginSchema = Yup.object().shape({
    login: Yup.string()
      .required('Поле обязательно для заполнения')
      .test('email_or_phone', 'Некорректный телефон или E-mail', value => {
        return validateEmail(value) || validatePhone(value);
      }),
    password: Yup.string().required('Поле обязательно для заполнения'),
  });

  const validateEmail = email => {
    return Yup.string()
      .email()
      .isValidSync(email);
  };

  const validatePhone = phone => {
    return Yup.string()
      .phone('RU')
      .isValidSync(phone);
  };

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const checkPasswordVisibility = useCallback(e => {
    if (e.target.checked) {
      setPassInputType('text');
      return;
    }

    setPassInputType('password');
  }, []);

  return (
    <div className="login-form login-signin" id="kt_login_signin_form">
      <Formik
        initialValues={initialValues}
        validationSchema={LoginSchema}
        validateOnChange={false}
        onSubmit={(values, { setStatus, setSubmitting, setFieldValue, isSubmitting, errors, setErrors, resetForm }) => {
          setSubmitting(true);
          enableLoading();
          dispatch(action.fetchToken(values))
            .then(() => {
              dispatch(action.fetchUser());
            })
            .catch(e => {
              Swal.fire({
                title: 'Ошибка',
                text: e.message,
                icon: 'error',
                confirmButtonText: 'Продолжить',
                heightAuto: false,
              });
              disableLoading();
              setSubmitting(false);
              setStatus(e.message);
              dispatch(action.logout());
            });
        }}
      >
        {({ errors, status, touched, isSubmitting, handleSubmit }) => (
          <Form className="calculationTOForm" onSubmit={handleSubmit}>
            <div class="pb-10 pb-lg-10">
              <h3 class="font-weight-bolder text-dark font-size-h2 font-size-h1-lg">Вход</h3>
              <div class="text-muted font-weight-bold font-size-h4">
                Впервые у нас?&nbsp;
                <Link to={ROUTES.AUTH_REG} className="text-primary font-weight-bolder">
                  Создать аккаунт
                </Link>
              </div>
            </div>
            <EndPoint />
            <div class="form-group fv-plugins-icon-container has-danger">
              <label class="font-size-h6 font-weight-bolder text-dark">Телефон или E-mail</label>
              <Field
                type="text"
                placeholder="Введите телефон или E-mail"
                name="login"
                className={`form-control form-control-solid h-auto py-5 px-6 mr-2
                      ${(touched.login && errors.login && 'is-invalid') ||
                        (touched.login && !errors.login && 'is-valid')}`}
              />
              {touched.login && errors.login ? <div className="invalid-feedback">{errors.login}</div> : null}
            </div>
            <div class="form-group fv-plugins-icon-container has-danger">
              <div class="d-flex justify-content-between mt-n5">
                <label class="font-size-h6 font-weight-bolder text-dark">Пароль</label>
                <Link
                  to={ROUTES.AUTH_FORGOT}
                  className="text-primary font-size-h6 font-weight-bolder text-hover-primary pt-5"
                >
                  Забыли пароль?
                </Link>
              </div>
              <Field
                type={passInputType}
                placeholder="Введите ваш пароль"
                name="password"
                className={`form-control form-control-solid h-auto py-5 px-6 mr-2
                      ${(touched.password && errors.password && 'is-invalid') ||
                        (touched.password && !errors.password && 'is-valid')}`}
              />
              {touched.password && errors.password ? <div className="invalid-feedback">{errors.password}</div> : null}
            </div>
            <div className="text-left mb-5 mb-lg-5">
              <p className="font-weight-bold font-size-h8">
                Нажимая кнопку "Войти", Вы соглашаетесь с условиями{' '}
                <a target="_blank" href={PRIVACY}>
                  политики конфиденциальности
                </a>
                .
              </p>
            </div>
            <div class="pb-lg-0 pb-5">
              <button
                type="submit"
                disabled={isSubmitting}
                className="btn btn-primary font-weight-bolder font-size-lg px-8 my-3 mr-3"
              >
                <span>Войти</span>
                {loading && <span className="ml-3 spinner spinner-white"></span>}
              </button>
              <Link to={ROUTES.AUTH_FAST} className="btn btn-light-primary font-weight-bolder px-8 my-3 font-size-lg">
                <span class="svg-icon svg-icon-key svg-icon-md">
                  <SVG src={toAbsoluteUrl('/media/svg/icons/Communication/Sending mail.svg')} />
                </span>
                Войти по коду
              </Link>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default Login;
