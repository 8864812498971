import React, { useMemo, useRef } from 'react';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';

import { useRegistrationUIContext } from '../RegistrationUIContext';
import { validFormsData } from '../RegistrationUIHelpers';

const passwordRules = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{5,}$/;

const Schema = Yup.object().shape({
  password: Yup.string()
    .required('Поле обязательно для заполнения')
    .min(8, 'Пароль должен состоять минимум из 8 символов')
    .matches(/[a-zA-Z]/, 'Пароль должен содержать только латинского буквы и цифры.')
    .matches(passwordRules, {
      message: 'Пароль должен содержать минимум 1 заглавную букву, 1 строчную букву и 1 цифру.',
    }),
    password_confirmation: Yup.string().oneOf([Yup.ref('password')], 'Пароли не совпадают'),
  agree: Yup.bool().oneOf([true], 'Вы должны согласиться с условиями'),
});

export const Step3 = () => {
  const formikRef = useRef(null);
  const registrationUIContext = useRegistrationUIContext();
  const registrationUIProps = useMemo(() => {
    return {
      validDataStep3: registrationUIContext.validDataStep3,
      setValidDataStep3: registrationUIContext.setValidDataStep3,
      formDataStep3: registrationUIContext.formDataStep3,
      setFormDataStep3: registrationUIContext.setFormDataStep3,
    };
  }, [
    registrationUIContext.validDataStep3,
    registrationUIContext.setValidDataStep3,
    registrationUIContext.formDataStep3,
    registrationUIContext.setFormDataStep3,
  ]);

  const onValidate = e => {
    registrationUIProps.setFormDataStep3({
      ...registrationUIProps.formDataStep3,
      [e.target.name]: e.target.type == 'checkbox' ? e.target.checked : e.target.value,
    });
    formikRef.current
      .setFieldValue(e.target.name, e.target.type == 'checkbox' ? e.target.checked : e.target.value)
      .then(() => {
        registrationUIProps.setValidDataStep3(formikRef.current.isValid);
        validFormsData.step3 = formikRef.current.isValid;
      });
  };

  return (
    <Formik
      innerRef={formikRef}
      initialValues={{
        password: registrationUIProps.formDataStep3.password,
        password_confirmation: registrationUIProps.formDataStep3.password_confirmation,
        agree: registrationUIProps.formDataStep3.agree,
      }}
      validationSchema={Schema}
    >
      {({ errors, touched }) => (
        <Form className="form form-label-right">
          <div className="form-group fv-plugins-icon-container has-danger">
            <label className="font-size-h6 font-weight-bolder text-dark">Пароль</label>
            <Field
              type="password"
              placeholder="Введите пароль"
              name="password"
              onChange={e => onValidate(e)}
              className={`form-control form-control-solid h-auto py-5 px-6 mr-2
                  ${(touched.password && errors.password && 'is-invalid') ||
                    (touched.password && !errors.password && 'is-valid')}`}
            />
            {touched.password && errors.password ? <div className="invalid-feedback">{errors.password}</div> : null}
          </div>
          <div className="form-group fv-plugins-icon-container has-danger">
            <label className="font-size-h6 font-weight-bolder text-dark">Повтор пароля</label>
            <Field
              type="password"
              placeholder="Повторите пароль"
              name="password_confirmation"
              onChange={e => onValidate(e)}
              className={`form-control form-control-solid h-auto py-5 px-6 mr-2
                  ${(touched.password_confirmation && errors.password_confirmation && 'is-invalid') ||
                    (touched.password_confirmation && !errors.password_confirmation && 'is-valid')}`}
            />
            {touched.password_confirmation && errors.password_confirmation ? (
              <div className="invalid-feedback">{errors.password_confirmation}</div>
            ) : null}
          </div>

          <div className="form-group fv-plugins-icon-container has-danger">
            <label className="checkbox mb-0">
              <Field type="checkbox" name="agree" onChange={e => onValidate(e)} />
              <span></span>
              <div className="ml-2">
                Регистрируясь, Вы соглашаетесь с условиями <a href="https://atri-energo.ru/content/privacy_policy.html">политики конфиденциальности</a>.
              </div>
            </label>
            {touched.patronymic && errors.patronymic ? (
              <div className="invalid-feedback">{errors.patronymic}</div>
            ) : null}
          </div>
        </Form>
      )}
    </Formik>
  );
};
