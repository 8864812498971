import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import objectPath from 'object-path';
import { useHtmlClassService } from '../../_core/MetronicLayout';
import { Topbar } from './Topbar';
import { HeaderMenuWrapper } from './header-menu/HeaderMenuWrapper';

import logoImg from './../../../../assets/image/logo-letter-1-white.png';
import { ROUTES } from '../../../../app/Routes.models';

export function Header() {
  const uiService = useHtmlClassService();

  const layoutProps = useMemo(() => {
    return {
      headerClasses: uiService.getClasses('header', true),
      headerAttributes: uiService.getAttributes('header'),
      headerContainerClasses: uiService.getClasses('header_container', true),
      menuHeaderDisplay: objectPath.get(uiService.config, 'header.menu.self.display'),
    };
  }, [uiService]);

  return (
    <>
      <div className={`header ${layoutProps.headerClasses}`} id="kt_header" {...layoutProps.headerAttributes}>
        <div className={` ${layoutProps.headerContainerClasses} d-flex align-items-stretch justify-content-between`}>
          <div className="d-flex align-items-stretch mr-3">
            <div className="header-logo">
              <Link to={ROUTES.HOME}>
                <img className="logo-default max-h-40px" alt="Logo" src={logoImg} />
              </Link>
            </div>
            {layoutProps.menuHeaderDisplay && <HeaderMenuWrapper />}
          </div>
          <Topbar />
        </div>
      </div>
    </>
  );
}
