import React, { useState, useEffect, useCallback } from 'react';
import { useIntl } from 'react-intl';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { Snackbar } from '@material-ui/core';
import { useFormik } from 'formik';

import { ModalProgressBar } from './../../../../../_metronic/_partials/controls';
import { toAbsoluteUrl } from './../../../../../_metronic/_helpers';
import { daDataServices } from './../../../../services/daDataServices';
import * as action from './../../../Auth/_redux/authActions';
import { changeUserInfo } from './../../../Auth/_redux/authCrud';
import { ROUTES } from '../../../../Routes.models';

export const PersonaInformation = () => {
  const intl = useIntl();

  const [avatar, setAvatar] = useState('');
  const [formAddress, setFormAddress] = useState('');
  const [isShowAlertSnackbars, setIsShowAlertSnackbars] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [addressHelp, setAddressHelp] = useState([]);
  const [lastClickVal, setLastClickVal] = useState('');
  const [isShowAddressHelp, setisShowAddressHelp] = useState(false);

  const dispatch = useDispatch();
  const user = useSelector(state => state.auth.user, shallowEqual);

  const initialValues = {
    avatar: user.avatar || '',
    surname: user.surname || '',
    name: user.name || '',
    ochestvo: user.ochestvo || '',
    sex: user.sex || '',
    birthday: user.birthday || '',
    place: user.place || '',
    phone: user.phone || '',
    email: user.email || '',
  };

  const Schema = Yup.object().shape({
    avatar: Yup.string(),
    name: Yup.string().required('First name is required'),
    surname: Yup.string().required('Last name is required'),
    ochestvo: Yup.string(),
    // eslint-disable-next-line no-useless-escape
    phone: Yup.string().matches('^[+]?[(]?[0-9]{3}[)]?[-s.]?[0-9]{3}[-s.]?[0-9]{4,6}$', 'Phone number is not valid'),
    sex: Yup.string(),
    birthday: Yup.string(),
    place: Yup.string(),
    email: Yup.string()
      .email('Wrong email format')
      .required('Email is required'),
  });

  const getInputClasses = fieldname => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return 'is-invalid';
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return 'is-valid';
    }

    return '';
  };

  const formik = useFormik({
    initialValues,
    validationSchema: Schema,
    onSubmit: (values, { setSubmitting }) => {
      submitForm(values, setSubmitting);
    },
  });

  useEffect(() => {
    setAvatar(user.avatar || '');
    setFormAddress(user.place || '');
  }, [user]);

  const setPicture = useCallback(e => {
    e.persist();

    const fileToDataUri = file =>
      new Promise(resolve => {
        const reader = new FileReader();
        reader.onload = event => {
          resolve(event.target.result);
        };

        reader.readAsDataURL(file);
      });

    fileToDataUri(e.target.files[0]).then(dataUri => {
      setAvatar(dataUri);
    });
  }, []);

  useEffect(() => {
    if (user.place === formAddress || formAddress === '') {
      setisShowAddressHelp(false);
      return;
    }

    const getHelp = async () => {
      const { suggestions } = await daDataServices.getAddress({ userQuery: formAddress });
      const helpVariants = suggestions.map(variant => variant['value']);
      setAddressHelp(helpVariants);
    };

    if (formAddress.length !== 0 && lastClickVal !== formAddress) {
      getHelp();
      setisShowAddressHelp(true);
    }
  }, [formAddress, lastClickVal, user.place, formik.values]);

  const addressInput = useCallback(
    e => {
      setFormAddress(e.target.value);
      formik.setFieldValue('place', e.target.value);
      if (e.target.value === '') {
        setisShowAddressHelp(false);
        formik.setFieldValue('place', e.target.value);
      }
    },
    [formik],
  );

  const getUserPic = useCallback(() => {
    if (!avatar) {
      return 'none';
    }

    return `url(${avatar})`;
  }, [avatar]);

  const removePic = useCallback(() => {
    setAvatar('');
  }, []);

  const addressHelpClick = useCallback(
    e => {
      formik.setFieldValue('place', e.target.innerHTML);
      setLastClickVal(e.target.innerHTML);
      setFormAddress(e.target.innerHTML);
      setAddressHelp([]);
      setisShowAddressHelp(false);
    },
    [formik],
  );

  const submitForm = (values, setSubmitting) => {
    setSubmitting(true);
    let formData = new FormData();
    if (user.avatar !== avatar) {
      formData.append('avatar', avatar);
    }

    changeUserInfo(values, formAddress, formData)
      .then(() => {
        const updatedUser = Object.assign({}, user, values, { avatar: avatar });
        setTimeout(() => {
          dispatch(action.setUser(updatedUser));
          setAlertMessage('Данные успешно сохранены');
        }, 500);
      })
      .catch(() => {
        setAlertMessage('Произошла ошибка попробуйте позже');
      })
      .finally(() => {
        setSubmitting(false);
        setIsShowAlertSnackbars(true);
      });
  };

  const handleSnackbarClose = useCallback(() => {
    setIsShowAlertSnackbars(false);
  }, []);

  return (
    <form className="card card-custom card-stretch" encType="multipart/form-data" onSubmit={formik.handleSubmit}>
      {formik.isSubmitting && <ModalProgressBar variant="warning" />}
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={isShowAlertSnackbars}
        onClose={() => handleSnackbarClose()}
        ContentProps={{ 'aria-describedby': 'message-id' }}
        message={
          <span className="alertSnackbar" id="message-id">
            {alertMessage}
          </span>
        }
      />
      <div className="card-header py-3">
        <div className="card-title align-items-start flex-column">
          <h3 className="card-label font-weight-bolder text-dark">
            {intl.formatMessage({ id: 'PERSONAL_INFORMATION' })}
          </h3>
          <span className="text-muted font-weight-bold font-size-sm mt-1">
            {intl.formatMessage({ id: 'UPDATE_INFO' })}
          </span>
        </div>
        <div className="card-toolbar">
          <button
            type="submit"
            className="btn btn-success mr-2"
            disabled={formik.isSubmitting || (formik.touched && !formik.isValid)}
          >
            {intl.formatMessage({ id: 'SAVE_CHANGES' })}
          </button>
          <Link to={ROUTES.USER} className="btn btn-secondary">
            {intl.formatMessage({ id: 'CANCEL' })}
          </Link>
        </div>
      </div>
      <div className="form">
        <div className="card-body">
          <div className="row">
            <label className="col-xl-3"></label>
            <div className="col-lg-9 col-xl-6">
              <h5 className="font-weight-bold mb-6">{intl.formatMessage({ id: 'USER_INFO' })}</h5>
            </div>
          </div>
          <div className="form-group row">
            <label className="col-xl-3 col-lg-3 col-form-label">Avatar</label>
            <div className="col-lg-9 col-xl-6">
              <div
                className="image-input image-input-outline"
                id="kt_profile_avatar"
                style={{
                  backgroundImage: `url(${toAbsoluteUrl('/media/users/blank.png')}`,
                }}
              >
                <div className="image-input-wrapper" style={{ backgroundImage: `${getUserPic()}` }} />
                <label
                  className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                  data-action="change"
                  data-toggle="tooltip"
                  title=""
                  data-original-title="Change avatar"
                >
                  <i className="fa fa-pen icon-sm text-muted"></i>
                  <input
                    type="file"
                    name="avatar"
                    accept=".png, .jpg, .jpeg"
                    onChange={e => setPicture(e)}
                    disabled={formik.isSubmitting}
                  />
                  <input type="hidden" name="profile_avatar_remove" disabled={formik.isSubmitting} />
                </label>
                <span
                  className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                  data-action="cancel"
                  data-toggle="tooltip"
                  title=""
                  data-original-title="Cancel avatar"
                >
                  <i className="ki ki-bold-close icon-xs text-muted"></i>
                </span>
                <span
                  onClick={formik.isSubmitting ? null : () => removePic()}
                  className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                  data-action="remove"
                  data-toggle="tooltip"
                  title=""
                  data-original-title="Remove avatar"
                >
                  <i className="ki ki-bold-close icon-xs text-muted"></i>
                </span>
              </div>
              <span className="form-text text-muted">{intl.formatMessage({ id: 'FILE_TYPES' })}</span>
            </div>
          </div>
          <div className="form-group row">
            <label className="col-xl-3 col-lg-3 col-form-label">{intl.formatMessage({ id: 'LAST_NAME' })}</label>
            <div className="col-lg-9 col-xl-6">
              <input
                type="text"
                placeholder="Last name"
                className={`form-control form-control-lg form-control-solid ${getInputClasses('surname')}`}
                name="surname"
                value={formik.values.surname}
                disabled={formik.isSubmitting}
                {...formik.getFieldProps('surname')}
              />
              {formik.touched.surname && formik.errors.surname ? (
                <div className="invalid-feedback">{formik.errors.surname}</div>
              ) : null}
            </div>
          </div>
          <div className="form-group row">
            <label className="col-xl-3 col-lg-3 col-form-label">{intl.formatMessage({ id: 'FIRST_NAME' })}</label>
            <div className="col-lg-9 col-xl-6">
              <input
                type="text"
                placeholder="First name"
                className={`form-control form-control-lg form-control-solid ${getInputClasses('name')}`}
                name="name"
                value={formik.values.name}
                disabled={formik.isSubmitting}
                {...formik.getFieldProps('name')}
              />
              {formik.touched.name && formik.errors.name ? (
                <div className="invalid-feedback">{formik.errors.name}</div>
              ) : null}
            </div>
          </div>
          <div className="form-group row">
            <label className="col-xl-3 col-lg-3 col-form-label">{intl.formatMessage({ id: 'PATRONYMIC' })}</label>
            <div className="col-lg-9 col-xl-6">
              <input
                type="text"
                placeholder={intl.formatMessage({ id: 'PATRONYMIC' })}
                className={`form-control form-control-lg form-control-solid ${getInputClasses('ochestvo')}`}
                name="ochestvo"
                value={formik.values.ochestvo}
                disabled={formik.isSubmitting}
                {...formik.getFieldProps('ochestvo')}
              />
              {formik.touched.ochestvo && formik.errors.ochestvo ? (
                <div className="invalid-feedback">{formik.errors.ochestvo}</div>
              ) : null}
            </div>
          </div>
          <div className="form-group row">
            <label className="col-xl-3 col-lg-3 col-form-label">{intl.formatMessage({ id: 'SEX' })}</label>
            <div className="col-lg-9 col-xl-6">
              <select
                className={`form-control form-control-lg form-control-solid ${getInputClasses('sex')}`}
                name="sex"
                value="1"
                onChange={formik.handleChange}
                {...formik.getFieldProps('sex')}
              >
                <option value="1">{intl.formatMessage({ id: 'SEXMALE' })}</option>
                <option value="2">{intl.formatMessage({ id: 'SEXFEMALE' })}</option>
              </select>
            </div>
          </div>
          <div className="form-group row">
            <label className="col-xl-3 col-lg-3 col-form-label">{intl.formatMessage({ id: 'BIRTHDAY' })}</label>
            <div className="col-lg-9 col-xl-6">
              <input
                className={`form-control form-control-lg form-control-solid ${getInputClasses('birthday')}`}
                type="date"
                name="birthday"
                value={formik.values.birthday}
                disabled={formik.isSubmitting}
                {...formik.getFieldProps('birthday')}
              />
              {formik.touched.birthday && formik.errors.birthday ? (
                <div className="invalid-feedback">{formik.errors.birthday}</div>
              ) : null}
            </div>
          </div>
          <div className="form-group row">
            <label className="col-xl-3 col-lg-3 col-form-label">{intl.formatMessage({ id: 'ADDRESS' })}</label>
            <div className="col-lg-9 col-xl-6">
              <div className="position-relative">
                <input
                  type="text"
                  placeholder={intl.formatMessage({ id: 'ADDRESS' })}
                  className={`form-control form-control-lg form-control-solid ${getInputClasses('place')}`}
                  name="place"
                  onChange={e => addressInput(e)}
                  autoComplete="nope"
                  value={formAddress || ''}
                  disabled={formik.isSubmitting}
                />
                <select
                  className={`custom-select position-absolute z-10 ${isShowAddressHelp ? 'd-block' : 'd-none'}`}
                  size={addressHelp.length <= 1 ? addressHelp.length + 1 : addressHelp.length - 1}
                >
                  {addressHelp.map(helpItem => (
                    <option key={helpItem} onClick={e => addressHelpClick(e)}>
                      {helpItem}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="row">
            <label className="col-xl-3"></label>
            <div className="col-lg-9 col-xl-6">
              <h5 className="font-weight-bold mt-10 mb-6">{intl.formatMessage({ id: 'CONTACT_INFO' })}</h5>
            </div>
          </div>
          <div className="form-group row">
            <label className="col-xl-3 col-lg-3 col-form-label">{intl.formatMessage({ id: 'CONTACT_PHONE' })}</label>
            <div className="col-lg-9 col-xl-6">
              <div className="input-group input-group-lg input-group-solid">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <i className="fa fa-phone"></i>
                  </span>
                </div>
                <input
                  type="text"
                  placeholder="+1(123)112-11-11"
                  className={`form-control form-control-lg form-control-solid ${getInputClasses('phone')}`}
                  name="phone"
                  value={formik.values.phone}
                  disabled={formik.isSubmitting}
                  {...formik.getFieldProps('phone')}
                />
              </div>
              {formik.touched.phone && formik.errors.phone ? (
                <div className="invalid-feedback display-block">{formik.errors.phone}</div>
              ) : null}
              <span className="form-text text-muted">We'll never share your phone with anyone else.</span>
            </div>
          </div>
          <div className="form-group row">
            <label className="col-xl-3 col-lg-3 col-form-label">Email</label>
            <div className="col-lg-9 col-xl-6">
              <div className="input-group input-group-lg input-group-solid">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <i className="fa fa-at"></i>
                  </span>
                </div>
                <input
                  type="email"
                  placeholder="Email"
                  className={`form-control form-control-lg form-control-solid ${getInputClasses('email')}`}
                  name="email"
                  value={formik.values.email}
                  disabled={formik.isSubmitting}
                  {...formik.getFieldProps('email')}
                />
              </div>
              {formik.touched.email && formik.errors.email ? (
                <div className="invalid-feedback display-block">{formik.errors.email}</div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};
