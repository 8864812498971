import { Field, Formik } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import Select from 'react-select';

import { LoadingDialog, ModalProgressBar } from '../../../_metronic/_partials/controls';
import * as actions from './../../modules/Support/redux/reportsActions';

export const ModalReport = ({ showReportsModal, setShowReportsModal }) => {
  const [someResultText, setSomeResultText] = useState();
  const [dialogShow, setDialogShow] = useState(false);
  const [loading, setloading] = useState(false);
  const [avatar, setAvatar] = useState('');
  const dispatch = useDispatch();

  const initialValues = {
    type: 'lk',
    topic: '',
    message: '',
    file: '',
  };

  const Schema = Yup.object().shape({
    type: Yup.string().required('Выберите тип заявки'),
    topic: Yup.string().required('Введите тему письма'),
    message: Yup.string().required('Введите Ваше сообщение'),
  });

  const sendReport = (values, setSubmitting, resetForm) => {
    setloading(true);
    values.file = avatar;

    dispatch(actions.fetchCreateReport({ title: values.topic, type: values.type }))
      .then(({ id, text }) => {
        return dispatch(
          actions.fetchCreateMsgsReport({ /* pictures: values.file,  */ support_id: id, message: values.message }),
        )
          .then(() => values.file && dispatch(actions.fetchCreateFileReport({ support_id: id, media: values.file })))
          .then(() => text);
      })
      .then(text => {
        setSomeResultText(text);
        dispatch(actions.fetchShowReports());
        setloading(false);
        setAvatar('');
      });
  };

  const setPicture = useCallback(e => {
    e.persist();
    setAvatar(e.target.files[0]);
  }, []);

  useEffect(() => {
    if (someResultText) {
      setDialogShow(true);
      setTimeout(() => {
        setDialogShow(false);
        setShowReportsModal(false);
        setSomeResultText('');
      }, 2000);
    }
  }, [dispatch, setShowReportsModal, someResultText]);

  const closeReportModal = () => {
    setAvatar('');
    setShowReportsModal(false);
  };

  const SUPPORT_TYPE = [
    { value: 'lk', label: 'Работа личного кабинета' },
    { value: 'price', label: 'Подбор оборудования' },
    { value: 'calculation', label: 'Прайс листы и скидки' },
  ];

  return (
    <>
      <LoadingDialog isLoading={dialogShow} text={someResultText} />
      {!dialogShow && (
        <Modal show={showReportsModal} backdrop="static" keyboard={false}>
          <Formik
            initialValues={initialValues}
            validationSchema={Schema}
            validateOnChange={true}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              sendReport(values, setSubmitting, resetForm);
            }}
          >
            {({ errors, touched, isSubmitting, isValid, handleSubmit }) => (
              <form id="modalReports" className="card card-custom" onSubmit={handleSubmit}>
                {loading && <ModalProgressBar />}
                <Modal.Header closeButton>
                  <Modal.Title>Нашли ошибку?</Modal.Title>
                </Modal.Header>
                {!loading ? (
                  <>
                    <Modal.Body>
                      <p>
                        Сообщите пожалуйста об ошибке, обнаруженной в ходе работы личного кабинета. Мы постараемся ее
                        исправить в кратчайшие сроки. О результате вы можете посмотреть в разделе «обращения»
                      </p>
                      <label className="col-form-label">Тип заявки:</label>
                      <Field
                        as="select"
                        name="type"
                        className={`form-control mb-2 ${(touched.type && errors.type && 'is-invalid') ||
                          (touched.type && !errors.type && 'is-valid')}`}
                      >
                        {SUPPORT_TYPE.map(use => (
                          <option key={use.value} value={use.value}>
                            {use.label}
                          </option>
                        ))}
                      </Field>

                      {touched.type && errors.type ? <div className="invalid-feedback">{errors.type}</div> : null}
                      <label className="col-form-label">Тема:</label>
                      <Field
                        as="input"
                        name="topic"
                        placeholder="Тема сообщения"
                        className={`form-control form-control-lg form-control-solid mb-2
                  ${(touched.topic && errors.topic && 'is-invalid') || (touched.topic && !errors.topic && 'is-valid')}`}
                      />
                      {touched.topic && errors.topic ? <div className="invalid-feedback">{errors.topic}</div> : null}
                      <div>
                        <label className="col-form-label">Текст:</label>
                        <Field
                          as="textarea"
                          name="message"
                          placeholder="Ваше сообщение"
                          className={`form-control form-control-lg form-control-solid mb-2
                    ${(touched.message && errors.message && 'is-invalid') ||
                      (touched.message && !errors.message && 'is-valid')}`}
                        />
                        {touched.message && errors.message ? (
                          <div className="invalid-feedback">{errors.message}</div>
                        ) : null}
                      </div>
                      <label className="col-form-label">Файл:</label>
                      <div className="image-input image-input-outline d-block">
                        <label
                          className="btn btn-primary position-static mr-2"
                          data-action="change"
                          data-toggle="tooltip"
                          title=""
                          data-original-title="Change avatar"
                        >
                          <span className="svg-icon svg-icon-md svg-icon-primary">Прикрепить файл</span>
                          <input type="file" name="avatar" onChange={e => setPicture(e)} />
                        </label>
                        <span className="">{avatar.name}</span>
                      </div>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button variant="secondary" onClick={() => closeReportModal(false)}>
                        Закрыть
                      </Button>
                      <Button type="submit" variant="primary">
                        Отправить
                      </Button>
                    </Modal.Footer>
                  </>
                ) : (
                  <>
                    <Modal.Body>
                      <p>Отправляется...</p>
                    </Modal.Body>
                  </>
                )}
              </form>
            )}
          </Formik>
        </Modal>
      )}
    </>
  );
};
