import React from 'react';
import { ScadaListCard } from './ScadaListCard';
import { ScadaListUIProvider } from './ScadaListUIContext';

export const ScadaListPage = () => {
  return (
    <ScadaListUIProvider>
      <ScadaListCard />
    </ScadaListUIProvider>
  );
};