// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useMemo } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import { shallowEqual, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import SVG from 'react-inlinesvg';

import {
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
  headerSortingClasses,
  toAbsoluteUrl,
} from '../../../../../../_metronic/_helpers';
import * as uiHelpers from '../MyObjectsUIHelpers';
import { Pagination } from '../../../../../../_metronic/_partials/controls';
import { useMyObjectsUIContext } from '../MyObjectsUIContext';

export function MyObjectsTable() {
  const intl = useIntl();
  const myObjectsUIContext = useMyObjectsUIContext();
  const myObjectsUIProps = useMemo(() => {
    return {
      queryParams: myObjectsUIContext.queryParams,
      setQueryParams: myObjectsUIContext.setQueryParams,
      objectMapButtonClick: myObjectsUIContext.objectMapButtonClick,
    };
  }, [myObjectsUIContext]);

  const { currentState } = useSelector(state => ({ currentState: state.myObjects }), shallowEqual);
  const { totalCount, entities, listLoading } = currentState;

  const columns = [
    {
      dataField: 'id',
      text: '#',
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: 'name',
      text: 'Название объекта',
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: 'address',
      text: 'Адрес объекта',
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: 'action',
      text: intl.formatMessage({ id: 'TABLE.ACTION' }),
      formatter: (_cell, row, _rowIndex) => {
        return (
          <button
            title="Открыть карту"
            className="btn btn-icon btn-light btn-hover-success btn-sm mx-3"
            onClick={() => myObjectsUIProps.objectMapButtonClick(row.id)}
          >
            <span className="svg-icon svg-icon-md svg-icon-success">
              <SVG src={toAbsoluteUrl('/media/svg/icons/Home/Earth.svg')} />
            </span>
          </button>
        );
      },
      classes: 'text-center',
      headerClasses: 'text-center',
    },
  ];

  // Table pagination properties
  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Показывать строки с {from} по {to} из {size}
    </span>
  );

  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: myObjectsUIProps.queryParams.pageSize,
    page: myObjectsUIProps.queryParams.pageNumber,
    paginationTotalRenderer: customTotal,
  };

  return (
    <>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination isLoading={listLoading} paginationProps={paginationProps}>
              <BootstrapTable
                wrapperClasses="table-responsive"
                bordered={false}
                classes="table table-head-custom table-vertical-center overflow-hidden"
                bootstrap4
                remote
                keyField="id"
                data={entities === null ? [] : entities}
                columns={columns}
                defaultSorted={uiHelpers.defaultSorted}
                onTableChange={getHandlerTableChange(myObjectsUIProps.setQueryParams)}
                {...paginationTableProps}
              >
                <PleaseWaitMessage entities={entities} />
                <NoRecordsFoundMessage entities={entities} />
              </BootstrapTable>
            </Pagination>
          );
        }}
      </PaginationProvider>
    </>
  );
}
