import { initialFilter } from '../NotificationsUIHelpers';
import * as requestFromServer from './notificationsCrud';
import { notificationsSlice, callTypes } from './notificationsSlice';

const { actions } = notificationsSlice;

export const fetchNotifications = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findNotifications(queryParams)
    .then(response => {
      const { totalCount, entities } = response.data;
      dispatch(actions.notificationsFetched({ totalCount, entities }));
    })
    .catch(error => {
      error.clientMessage = "Can't find notifications!";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchLastNotifications = (queryParams = initialFilter) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findNotifications(queryParams)
    .then(response => {
      const { totalCount, entities } = response.data;
      dispatch(actions.notificationsFetched({ totalCount, entities }));
    })
    .catch(error => {
      error.clientMessage = "Can't find notifications!";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

/* export const fetchUnreadNotifications = (queryParams = initialFilter) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findNotifications(queryParams)
    .then(response => {
      const { totalCount, entities } = response.data;
      dispatch(actions.notificationsFetched({ totalCount, entities }));
    })
    .catch(error => {
      error.clientMessage = "Can't find notifications!";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
}; */
