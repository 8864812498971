import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { LayoutSplashScreen } from '../../../../_metronic/layout';
import { ROUTES } from '../../../Routes.models';
import * as action from './../_redux/authActions';

export const Logout = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(action.logout());
  }, [dispatch]);

  const { authToken } = useSelector(state => state.auth);

  return authToken ? <LayoutSplashScreen /> : <Redirect to={ROUTES.AUTH_LOGIN} />;
};
