import React from 'react';
import { ScadaTypeCssClasses, ScadaStatus } from '../../ScadaListUIHelpers';

export function StatusColumnFormatter(cellContent, row) {
  const getLabelCssClasses = () => {
    return `label label-lg label-light-${ScadaTypeCssClasses[row.status]} label-inline`;
  };

  return <span className={getLabelCssClasses()}>{ScadaStatus[cellContent]}</span>;
}
