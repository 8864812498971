/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from 'react';
import { useLocation } from 'react-router';
import { NavLink } from 'react-router-dom';
import { useIntl } from 'react-intl';

import { checkIsActive } from '../../../../_helpers';
import { ROUTES } from '../../../../../app/Routes.models';
import { useSelector } from 'react-redux';

export function HeaderMenu({ layoutProps }) {
  const intl = useIntl();
  const location = useLocation();

  const { authState } = useSelector(state => ({ authState: state.auth }));
  const isScadaEnabled = authState.user.module.scada.enable;

  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url, hasSubmenu) ? 'menu-item-active' : '';
  };

  return (
    <div
      id="kt_header_menu"
      className={`header-menu header-menu-left header-menu-mobile ${layoutProps.ktMenuClasses}`}
      {...layoutProps.headerMenuAttributes}
    >
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        <li className={`menu-item menu-item-rel ${getMenuItemActive(ROUTES.HOME)}`}>
          <NavLink className="menu-link" to={ROUTES.HOME}>
            <span className="menu-text">{intl.formatMessage({ id: 'HEADER_ASIDE.HOME' })}</span>
          </NavLink>
        </li>
        <li
          data-menu-toggle={layoutProps.menuDesktopToggle}
          aria-haspopup="true"
          className={`menu-item menu-item-submenu menu-item-rel ${getMenuItemActive(ROUTES.ORDERS, true)}`}
        >
          <NavLink className="menu-link menu-toggle" to={ROUTES.ORDERS}>
            <span className="menu-text">{intl.formatMessage({ id: 'HEADER_ASIDE.ORDERS' })}</span>
            <i className="menu-arrow"></i>
          </NavLink>
          <div className="menu-submenu menu-submenu-classic menu-submenu-left">
            <ul className="menu-subnav">
              <li className={`menu-item ${getMenuItemActive(ROUTES.ORDERS)}`}>
                <NavLink className="menu-link" to={ROUTES.ORDERS}>
                  <span className="menu-text">{intl.formatMessage({ id: 'ORDERS' })}</span>
                </NavLink>
              </li>
              <li className={`menu-item ${getMenuItemActive(ROUTES.ORDERS_ARCHIVE)}`}>
                <NavLink className="menu-link" to={ROUTES.ORDERS_ARCHIVE}>
                  <span className="menu-text">{intl.formatMessage({ id: 'ORDERS_ARCHIVE' })}</span>
                </NavLink>
              </li>
            </ul>
          </div>
        </li>
        <li
          data-menu-toggle={layoutProps.menuDesktopToggle}
          aria-haspopup="true"
          className={`menu-item menu-item-submenu menu-item-rel ${getMenuItemActive(ROUTES.DESIGN, true)}`}
        >
          <NavLink className="menu-link menu-toggle" to={ROUTES.DESIGN}>
            <span className="menu-text">{intl.formatMessage({ id: 'HEADER_ASIDE.DESIGN' })}</span>
            <i className="menu-arrow"></i>
          </NavLink>
          <div className="menu-submenu menu-submenu-classic menu-submenu-left">
            <ul className="menu-subnav">
              <li className={`menu-item ${getMenuItemActive(ROUTES.CALCULATIONS)}`}>
                <NavLink className="menu-link" to={ROUTES.CALCULATIONS}>
                  <span className="menu-text">{intl.formatMessage({ id: 'CALCULATIONS' })}</span>
                </NavLink>
              </li>
              <li
                className={`menu-item menu-item-submenu ${getMenuItemActive(ROUTES.NEW_CALC, true)}`}
                data-menu-toggle="hover"
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to={ROUTES.NEW_CALC}>
                  <span className="menu-text">Новый расчет</span>
                  <i className="menu-arrow" />
                </NavLink>

                <div className={`menu-submenu menu-submenu-classic menu-submenu-right`}>
                  <ul className="menu-subnav">
                    {/* <li className={`menu-item ${getMenuItemActive(ROUTES.NEW_BTP)}`}>
                      <NavLink className="menu-link" to={ROUTES.NEW_BTP}>
                        <span className="menu-text">
                          Расчет БТП &nbsp;<b>β</b>
                        </span>
                      </NavLink>
                    </li> */}
                    <li
                      className={`menu-item menu-item-submenu ${getMenuItemActive(ROUTES.NEW_TO, true)}`}
                      data-menu-toggle="hover"
                      aria-haspopup="true"
                    >
                      <NavLink className="menu-link" to={ROUTES.NEW_TO}>
                        <span className="menu-text">Расчет теплообменника</span>
                        <i className="menu-arrow" />
                      </NavLink>
                      <div className={`menu-submenu menu-submenu-classic menu-submenu-right`}>
                        <ul className="menu-subnav">
                          <li className={`menu-item ${getMenuItemActive(ROUTES.NEW_TO1)}`}>
                            <NavLink className="menu-link" to={ROUTES.NEW_TO1}>
                              <span className="menu-text">Стандартный расчет</span>
                            </NavLink>
                          </li>
                          <li className={`menu-item ${getMenuItemActive(ROUTES.NEW_TO2)}`}>
                            <NavLink className="menu-link" to={ROUTES.NEW_TO2}>
                              <span className="menu-text">Двухступенчатая схема</span>
                            </NavLink>
                          </li>
                          <li className={`menu-item ${getMenuItemActive(ROUTES.NEW_TO3)}`}>
                            <NavLink className="menu-link" to={ROUTES.NEW_TO3}>
                              <span className="menu-text">Конденсация</span>
                            </NavLink>
                          </li>
                        </ul>
                      </div>
                    </li>
                    <li className={`menu-item ${getMenuItemActive(ROUTES.NEW_PUMP)}`}>
                      <NavLink className="menu-link" to={ROUTES.NEW_PUMP}>
                        <span className="menu-text">
                          Расчет насосов
                        </span>
                      </NavLink>
                    </li>
                    <li className={`menu-item ${getMenuItemActive(ROUTES.NEW_PUMP_STATION)}`}>
                      <NavLink className="menu-link" to={ROUTES.NEW_PUMP_STATION}>
                        <span className="menu-text">
                          Расчет насосной станции
                        </span>
                      </NavLink>
                    </li>
                    {/* <li className={`menu-item ${getMenuItemActive(ROUTES.NEW_FLOW_METER)}`}>
                      <NavLink className="menu-link" to={ROUTES.NEW_FLOW_METER}>
                        <span className="menu-text">
                          Расчет узла учета &nbsp;<b>β</b>
                        </span>
                      </NavLink>
                    </li> */}
                  </ul>
                </div>
              </li>
            </ul>
          </div>
        </li>
        <li
          data-menu-toggle={layoutProps.menuDesktopToggle}
          aria-haspopup="true"
          className={`menu-item menu-item-submenu menu-item-rel ${getMenuItemActive(ROUTES.TOOLS, true)}`}
        >
          <NavLink className="menu-link menu-toggle" to={ROUTES.TOOLS}>
            <span className="menu-text">Инструменты</span>
            <i className="menu-arrow"></i>
          </NavLink>
          <div className="menu-submenu menu-submenu-classic menu-submenu-left">
            <ul className="menu-subnav">
              <li className={`menu-item ${getMenuItemActive(ROUTES.TOOLS_SCANNER)}`}>
                <NavLink className="menu-link" to={ROUTES.TOOLS_SCANNER}>
                  <span className="menu-text">
                    Сканер документов &nbsp;<b>β</b>
                  </span>
                </NavLink>
              </li>
              {isScadaEnabled && (
                <li className={`menu-item ${getMenuItemActive(ROUTES.TOOLS_SCADA_LIST)}`}>
                  <NavLink className="menu-link" to={ROUTES.TOOLS_SCADA_LIST}>
                    <span className="menu-text">Диспетчеризация</span>
                  </NavLink>
                </li>
              )}
            </ul>
          </div>
        </li>
        <li
          data-menu-toggle={layoutProps.menuDesktopToggle}
          aria-haspopup="true"
          className={`menu-item menu-item-submenu menu-item-rel ${getMenuItemActive(ROUTES.USER, true)}`}
        >
          <NavLink className="menu-link menu-toggle" to={ROUTES.USER}>
            <span className="menu-text">{intl.formatMessage({ id: 'HEADER_ASIDE.PERSONAL_DATA' })}</span>
            <i className="menu-arrow"></i>
          </NavLink>
          <div className="menu-submenu menu-submenu-classic menu-submenu-left">
            <ul className="menu-subnav">
              <li className={`menu-item ${getMenuItemActive(ROUTES.USER_PROFILE_INFO)}`}>
                <NavLink className="menu-link" to={ROUTES.USER_PROFILE}>
                  <span className="menu-text">{intl.formatMessage({ id: 'USERPROFILE' })}</span>
                </NavLink>
              </li>
              <li className={`menu-item ${getMenuItemActive(ROUTES.USER_ORGANISATIONS)}`}>
                <NavLink className="menu-link" to={ROUTES.USER_ORGANISATIONS}>
                  <span className="menu-text">{intl.formatMessage({ id: 'ORGANIZATIONS' })}</span>
                </NavLink>
              </li>
              <li className={`menu-item ${getMenuItemActive(ROUTES.USER_OBJECTS)}`}>
                <NavLink className="menu-link" to={ROUTES.USER_OBJECTS}>
                  <span className="menu-text">{intl.formatMessage({ id: 'OBJECTS' })}</span>
                </NavLink>
              </li>
            </ul>
          </div>
        </li>
        <li
          data-menu-toggle={layoutProps.menuDesktopToggle}
          aria-haspopup="true"
          className={`menu-item menu-item-submenu menu-item-rel ${getMenuItemActive(ROUTES.SUPPORT, true)}`}
        >
          <NavLink className="menu-link menu-toggle" to={ROUTES.SUPPORT}>
            <span className="menu-text">Поддержка</span>
            <i className="menu-arrow"></i>
          </NavLink>
          <div className="menu-submenu menu-submenu-classic menu-submenu-left">
            <ul className="menu-subnav">
              <li className={`menu-item ${getMenuItemActive(ROUTES.SUPPORT_REPORTS)}`}>
                <NavLink className="menu-link" to={ROUTES.SUPPORT_REPORTS}>
                  <span className="menu-text">Обращения</span>
                </NavLink>
              </li>
            </ul>
          </div>
        </li>
      </ul>
    </div>
  );
}
