import * as requestFromServer from './promotionsCrud';

import { promotionsSlice, callTypes } from './promotionsSlice';

const { actions } = promotionsSlice;

export const fetchPromotions = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.getProm }));
  return requestFromServer
    .getPromotions(queryParams)
    .then(response => {
      dispatch(actions.promotionsFetched(response));
    })
    .catch(error => {
      error.clientMessage = "Can't find promotions!";
      dispatch(actions.catchError({ error, callType: callTypes.getProm }));
    });
};
