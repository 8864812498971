import React, { useCallback } from 'react';
import { Modal } from 'react-bootstrap';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import * as actions from './../modules/Auth/_redux/authActions';

export const InfoToolTips = () => {
  const { authState } = useSelector(state => ({ authState: state.auth }), shallowEqual);
  const { showStartInfoTooltip } = authState;

  const dispatch = useDispatch();

  const disableStartInfoTooltips = useCallback(() => {
    dispatch(actions.disableStartInfoTooltip(false));
  }, [dispatch]);

  return (
    <Modal
      show={showStartInfoTooltip}
      onHide={disableStartInfoTooltips}
      animation={true}
      size="md"
      centered
    >
      <Modal.Header className="justify-content-center">
        <Modal.Title>Что вам дает личный кабинет?</Modal.Title>
      </Modal.Header>
      <Modal.Body className="mx-8">
        <div>
          <ul>
            <li className="font-weight-normal text-dark-75 pl-3 font-size-lg">Последние новости и акции</li>
            <li className="font-weight-normal text-dark-75 pl-3 font-size-lg mt-4">История заказов</li>
            <li className="font-weight-normal text-dark-75 pl-3 font-size-lg mt-4">Онлайн расчет блоков, теплообменников и насосного оборудования</li>
            <li className="font-weight-normal text-dark-75 pl-3 font-size-lg mt-4">Оповещение о новых функциях в личном кабинете</li>
            <li className="font-weight-normal text-dark-75 pl-3 font-size-lg mt-4">Поддержка</li>
            <li className="font-weight-normal text-dark-75 pl-3 font-size-lg mt-4">Доступ 24/7</li>
          </ul>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-primary mx-auto" onClick={()=>disableStartInfoTooltips()}>Закрыть</button>
      </Modal.Footer>
    </Modal>
  );
};