import React, { createContext, useContext, useState } from 'react';
import { initialFormsData, validFormsData } from './RegistrationUIHelpers';

const RegistrationUIContext = createContext();

export function useRegistrationUIContext() {
  return useContext(RegistrationUIContext);
}

export const RegistrationUIConsumer = RegistrationUIContext.Consumer;

export const RegistrationUIProvider = ({ children }) => {
  const [formDataStep1, setFormDataStep1] = useState({ ...initialFormsData.step1 });
  const [formDataStep2, setFormDataStep2] = useState({ ...initialFormsData.step2 });
  const [formDataStep3, setFormDataStep3] = useState({ ...initialFormsData.step3 });

  const [validDataStep1, setValidDataStep1] = useState(validFormsData.step1);
  const [validDataStep2, setValidDataStep2] = useState(validFormsData.step2);
  const [validDataStep3, setValidDataStep3] = useState(validFormsData.step3);

  const value = {
    formDataStep1,
    formDataStep2,
    formDataStep3,
    setFormDataStep1,
    setFormDataStep2,
    setFormDataStep3,
    validDataStep1,
    validDataStep2,
    validDataStep3,
    setValidDataStep1,
    setValidDataStep2,
    setValidDataStep3,
  };

  return <RegistrationUIContext.Provider value={value}>{children}</RegistrationUIContext.Provider>;
};
