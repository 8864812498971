import React, { useEffect, useMemo } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator';

import { useDispatch } from 'react-redux';

import {
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  getHandlerTableChange,
} from '../../../../../../../_metronic/_helpers';
import { Pagination } from '../../../../../../../_metronic/_partials/controls';
import { useScadaListUIContext } from '../ScadaListUIContext';
import * as uiHelpers from '../ScadaListUIHelpers';
import * as actions from '../../../../redux/scada/ScadaActions';
import * as columnFormatters from './column-formatters';
import { Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

export const ScadaListTable = ({ entities, loading, totalCount }) => {
  const scadaListUIContext = useScadaListUIContext();
  const dispatch = useDispatch();
  const history = useHistory();

  const scadaListUIProps = useMemo(() => {
    return {
      queryParams: scadaListUIContext.queryParams,
      setQueryParams: scadaListUIContext.setQueryParams,
    };
  }, [scadaListUIContext.queryParams, scadaListUIContext.setQueryParams]);

  useEffect(() => {
    dispatch(actions.getScadaList(scadaListUIProps.queryParams));
  }, [dispatch, scadaListUIProps.queryParams]);

  const columns = [
    {
      dataField: 'id',
      text: '#',
      sort: true,
    },
    {
      dataField: 'title',
      text: 'Название',
      sort: true,
    },
    {
      dataField: 'oid_name',
      text: 'Организация',
      sort: true,
    },
    {
      dataField: 'address',
      text: 'Адрес',
      sort: true,
    },
    {
      dataField: 'status',
      text: 'Статус',
      formatter: columnFormatters.StatusColumnFormatter,
      sort: true,
    },
    {
      dataField: 'action',
      text: 'Действия',
      formatter: (_cell, row, _rowIndex) => {
        return (
          <Button variant="secondary" size="sm" onClick={() => history.push(`/tools/scada/${row.id}`)}>
            Открыть
          </Button>
        );
      },
      classes: 'text-center nowrap',
      headerClasses: 'text-center nowrap',
    },
  ];

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Показывать строки с {from} по {to} из {size}
    </span>
  );

  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: scadaListUIProps.queryParams.pageSize,
    page: scadaListUIProps.queryParams.pageNumber,
    paginationTotalRenderer: customTotal,
  };

  return (
    <PaginationProvider pagination={paginationFactory(paginationOptions)}>
      {({ paginationProps, paginationTableProps }) => {
        return (
          <Pagination isLoading={loading} paginationProps={paginationProps}>
            <BootstrapTable
              wrapperClasses="table-responsive"
              bordered={false}
              classes="table table-head-custom table-vertical-center overflow-hidden"
              bootstrap4
              remote
              keyField="id"
              data={entities}
              columns={columns}
              defaultSorted={uiHelpers.defaultSorted}
              onTableChange={getHandlerTableChange(scadaListUIProps.setQueryParams)}
              {...paginationTableProps}
            >
              <PleaseWaitMessage entities={entities} />
              <NoRecordsFoundMessage entities={entities} />
            </BootstrapTable>
          </Pagination>
        );
      }}
    </PaginationProvider>
  );
};
