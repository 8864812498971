import { createSlice } from '@reduxjs/toolkit';

const initialPumpStationState = {
  pumpStationListLoading: false,
  pumpCalculationSaving: false,
  entities: null,
  pumpResult: null,
  pumpFormValues: null,
  saveResponseType: null,
  attachId: null,
  saveCalculationNum: null,
  manufactureLoading: false,
  pumpTypeUseState: '1',
  modelsLoading: false,
  manufacture: [],
  models: [],
};

export const callTypes = {
  list: 'list',
  saveCalc: 'saveCalc',
  manufacture: 'manufacture',
  models: 'models',
};

export const calculationPumpStationSlice = createSlice({
  name: 'calculationPumpStation',
  initialState: initialPumpStationState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.pumpStationListLoading = false;
        return;
      }
      if (action.payload.callType === callTypes.saveCalc) {
        state.pumpCalculationSaving = false;
        return;
      }
      if (action.payload.callType === callTypes.manufacture) {
        state.manufactureLoading = false;
        return;
      }
      if (action.payload.callType === callTypes.models) {
        state.modelsLoading = false;
        return;
      }
    },

    startCall: (state, action) => {
      state.error = null;
      state.attachId = null;
      state.saveCalculationNum = null;
      state.saveResponseType = null;
      state.responseType = null;

      if (action.payload.callType === callTypes.list) {
        state.pumpStationListLoading = true;
        return;
      }

      if (action.payload.callType === callTypes.saveCalc) {
        state.pumpCalculationSaving = true;
        return;
      }

      if (action.payload.callType === callTypes.manufacture) {
        state.manufactureLoading = true;
        return;
      }

      if (action.payload.callType === callTypes.models) {
        state.modelsLoading = true;
        return;
      }
    },

    pumpStationCalculationFetched: (state, action) => {
      const { data, queryParams } = action.payload;
      state.pumpStationListLoading = false;
      state.error = null;
      state.entities = data.entities;
      state.type_use = data.type_use;
      state.entities_links = {};
      data.entities.forEach((item, index) => {
        state.entities_links[item.cid] = index;
      });
      state.pumpResult = data.type;
      state.pumpFormValues = queryParams;
    },

    savePumpStationCalculationFetched: (state, action) => {
      const { data } = action.payload;
      state.pumpCalculationSaving = false;
      state.error = null;
      if (data.type === 'success') {
        state.attachId = data.aid;
        state.saveResponseType = data.type;
        state.saveCalculationNum = data.request;
        return;
      }
    },

    clearSaveResponseType: state => {
      state.saveResponseType = initialPumpStationState.saveResponseType;
      state.attachId = initialPumpStationState.attachId;
      state.saveCalculationNum = initialPumpStationState.saveCalculationNum;
    },

    changePumpTypeUseStateSlice: (state, action) => {
      state.pumpTypeUseState = action.payload;
    },

    manufactureStationFetched: (state, action) => {
      state.manufactureStation = action.payload.manufacture;
      state.manufactureStationLoading = false;
      state.error = null;
    },

    modelsFetched: (state, action) => {
      state.models = action.payload.models;
      state.modelsLoading = false;
      state.error = null;
    },
  },
});
