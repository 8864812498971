/* eslint-disable no-unused-vars */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */

import React, { useCallback, useEffect, useRef, useState } from "react";
import SVG from "react-inlinesvg";

import { OverlayTrigger, Dropdown, Tab, Tooltip } from "react-bootstrap";
import { DropdownTopbarItemToggler } from "../../../_metronic/_partials/dropdowns";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { Nav } from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "./_redux/unreadNotifiActions";
import { UnreadNotifiRow } from "./UnreadNotifiRow";
import { NOTIFI_TYPE } from "./UnreadNotifiUIHelpers";
import { NavLink } from "react-router-dom";
import { ROUTES } from "../../Routes.models";

const perfectScrollbarOptions = {
  wheelSpeed: 2,
  wheelPropagation: false,
};

export const UnreadNotifi = () => {
  const [key, setKey] = useState("all");
  const [salesEnt, setSalesEnt] = useState([]);
  const [requestEnt, setRequestEnt] = useState([]);
  const [supportEnt, setSupportEnt] = useState([]);

  const bgImage = toAbsoluteUrl("/media/misc/bg-1.jpg");
  const dropdownToggleUnreadNotifi = useRef(null);

  const initialFilter = {
    filter: {
      module: null,
      status: {
        operator: '=',
        value: NOTIFI_TYPE.unreadNotifi,
      },
    },
    sortOrder: "desc",
    sortField: "id",
    limit: 200,
  };

  const { currentState } = useSelector(
    (state) => ({ currentState: state.unreadNotifi }),
    shallowEqual
  );

  const { totalCount, entities, listLoading } = currentState;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.fetchUnreadNotifi(initialFilter));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (entities && entities.length) {
      setSalesEnt([...entities].filter(item => item.module === 'sales'));
      setRequestEnt([...entities].filter(item => item.module === 'request'));
      setSupportEnt([...entities].filter(item => item.module === 'support'));
    };
  }, [entities]);

  const HandlerDropdown = useCallback(()=>dropdownToggleUnreadNotifi.current.click(), []);

  return (
    <Dropdown drop="down" alignRight>
      <Dropdown.Toggle
        as={DropdownTopbarItemToggler}
        id="kt_quick_notifications_toggle"
        ref={dropdownToggleUnreadNotifi}
      >
        <OverlayTrigger
          placement="bottom"
          overlay={
            <Tooltip id="user-notification-tooltip">
              Уведомления
            </Tooltip>
          }
        >
          <div
            className="btn btn-icon btn-hover-transparent-white btn-dropdown btn-lg mr-1 pulse pulse-primary"
            id="kt_quick_notifications_toggle"
          >
            <span className="svg-icon svg-icon-xl">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Code/Compiling.svg")}
              />
            </span>
            <span className="pulse-ring" />
          </div>
        </OverlayTrigger>
      </Dropdown.Toggle>
      <Dropdown.Menu className="dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim-up dropdown-menu-lg">
        <form>
          <div
            className="d-flex flex-column pt-12 bgi-size-cover bgi-no-repeat rounded-top"
            style={{ backgroundImage: `url(${bgImage})` }}
          >
            <h4 className="d-flex flex-center rounded-top">
              <span className="text-white">Уведомления</span>
              <NavLink className="" to={ROUTES.NOTIFI}>
                <span className="btn btn-text btn-success btn-sm font-weight-bold btn-font-md ml-2">{`${totalCount} новых`}</span>
              </NavLink>
            </h4>
            <Tab.Container defaultActiveKey={key}>
              <Nav
                as="ul"
                className="nav nav-bold nav-tabs nav-tabs-line nav-tabs-line-3x nav-tabs-line-transparent-white nav-tabs-line-active-border-success mt-3 px-8"
                onSelect={(_key) => setKey(_key)}
              >
                <Nav.Item className="nav-item" as="li">
                  <Nav.Link
                    eventKey="all"
                    className={`nav-link show ${
                      key === "all" ? "active" : ""
                    }`}
                  >
                    Все
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="nav-item" as="li">
                  <Nav.Link
                    eventKey="sales"
                    className={`nav-link show ${
                      key === "sales" ? "active" : ""
                    }`}
                  >
                    Заказы
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="nav-item" as="li">
                  <Nav.Link
                    eventKey="request"
                    className={`nav-link show ${
                      key === "request" ? "active" : ""
                    }`}
                  >
                    Расчеты
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="nav-item" as="li">
                  <Nav.Link
                    eventKey="support"
                    className={`nav-link show ${
                      key === "support" ? "active" : ""
                    }`}
                  >
                    Обращения
                  </Nav.Link>
                </Nav.Item>
              </Nav>
              <Tab.Content className="tab-content">
                <Tab.Pane
                  eventKey="all"
                >
                  {entities ? (
                    <PerfectScrollbar
                      options={perfectScrollbarOptions}
                      className="navi navi-hover scroll my-4"
                      style={{ maxHeight: "300px", position: "relative" }}
                    >
                      {entities.map((entity, entityIndex) => {
                        if (entityIndex < 10) {
                          return <UnreadNotifiRow key={entity.id} title={entity.title} icon={entity.icon} date={entity.date} url={entity.url} HandlerDropdown={HandlerDropdown} />
                        };

                        return null;
                      })}
                    </PerfectScrollbar>
                  ) : (
                    <p className="px-6 py-4 mb-0">Уведомления отсутствуют</p>
                  )}
                </Tab.Pane>
                <Tab.Pane
                  eventKey="sales"
                >
                  {salesEnt.length ? (
                    <PerfectScrollbar
                      options={perfectScrollbarOptions}
                      className="navi navi-hover scroll my-4"
                      style={{ maxHeight: "300px", position: "relative" }}
                    >
                      {salesEnt.map((entity, entityIndex) => {
                        if (entityIndex < 10) {
                          return <UnreadNotifiRow key={entity.id} title={entity.title} icon={entity.icon} date={entity.date} url={entity.url} HandlerDropdown={HandlerDropdown} />
                        };

                        return null;
                      })}
                    </PerfectScrollbar>
                  ) : (
                    <p className="px-6 py-4 mb-0">Уведомления отсутствуют</p>
                  )}
                </Tab.Pane>
                <Tab.Pane
                  eventKey="request"
                >
                  {requestEnt.length ? (
                    <PerfectScrollbar
                      options={perfectScrollbarOptions}
                      className="navi navi-hover scroll my-4"
                      style={{ maxHeight: "300px", position: "relative" }}
                    >
                      {requestEnt.map((entity, entityIndex) => {
                        if (entityIndex < 10) {
                          return <UnreadNotifiRow key={entity.id} title={entity.title} icon={entity.icon} date={entity.date} url={entity.url} HandlerDropdown={HandlerDropdown} />
                        };

                        return null;
                      })}
                    </PerfectScrollbar>
                  ) : (
                    <p className="px-6 py-4 mb-0">Уведомления отсутствуют</p>
                  )}
                </Tab.Pane>
                <Tab.Pane
                  eventKey="support"
                >
                  {supportEnt.length ? (
                    <PerfectScrollbar
                      options={perfectScrollbarOptions}
                      className="navi navi-hover scroll my-4"
                      style={{ maxHeight: "300px", position: "relative" }}
                    >
                      {supportEnt.map((entity, entityIndex) => {
                        if (entityIndex < 10) {
                          return <UnreadNotifiRow key={entity.id} title={entity.title} icon={entity.icon} date={entity.date} url={entity.url} HandlerDropdown={HandlerDropdown} />
                        };

                        return null;
                      })}
                    </PerfectScrollbar>
                  ) : (
                    <p className="px-6 py-4 mb-0">Уведомления отсутствуют</p>
                  )}
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </div>
        </form>
      </Dropdown.Menu>
    </Dropdown>
  );
};