import React from "react";
import { NotificationsCard } from "./NotificationsCard";
import { NotificationsUIProvider } from "./NotificationsUIContext";

export const NotificationsPage = () => {
  return (
    <NotificationsUIProvider /* calculationsUIEvents={calculationsUIEvents} */>
      <NotificationsCard />
    </NotificationsUIProvider>
  );
};
