import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { ROUTES } from '../../../Routes.models';
import * as action from './../_redux/authActions';
const { REACT_APP_MODE, REACT_APP_REQ_DEV, REACT_APP_REQ_PROD } = process.env;
const ENDPOINT = REACT_APP_MODE === 'dev' ? REACT_APP_REQ_DEV : REACT_APP_REQ_PROD;
const PRIVACY = `${ENDPOINT}content/privacy_policy.html`;

const initialValues = {
  code: '',
};

export const CheckCode = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const { currentState } = useSelector(state => ({ currentState: state.auth }));
  const { checkCodeType, checkCodeLogin } = currentState;

  useEffect(() => {
    if (!checkCodeType) {
      history.push(ROUTES.AUTH_FAST);
    }
  }, [history, checkCodeType]);

  const Schema = Yup.object().shape({
    code: Yup.number().required('Поле обязательно для заполнения'),
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const backBtnHandler = useCallback(() => {
    dispatch(action.clearCheckCodeState());
    history.push(ROUTES.AUTH_FAST);
  }, [dispatch, history]);

  return (
    <div className="login-form login-signin" id="kt_login_signin_form">
      <Formik
        initialValues={initialValues}
        validationSchema={Schema}
        validateOnChange={false}
        onSubmit={(values, { setFieldValue, setStatus, setSubmitting }) => {
          setSubmitting(true);
          enableLoading();
          dispatch(action.registerByCode(values)).catch(e => {
            disableLoading();
            setSubmitting(false);
            setStatus(e.message);
          });
        }}
      >
        {({ errors, status, touched, isSubmitting, handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <div className="pb-10 pb-lg-10">
              <h3 className="font-weight-bolder text-dark font-size-h2 font-size-h1-lg">
                {checkCodeType === 'email' ? 'Введите E-mail код' : 'Введите код'}
              </h3>
              <div className="text-muted font-weight-bold font-size-h4">
                {checkCodeType === 'email' ? 'Почта получателя: ' : 'Номер получателя: '} {checkCodeLogin}
              </div>
            </div>
            {status ? (
              <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                <div className="alert-text font-weight-bold">{status}</div>
              </div>
            ) : null}
            {checkCodeType === 'phone' && (
              <div className="mb-5 alert alert-custom alert-light-info alert-dismissible">
                <div className="alert-text font-weight-bold">
                  В течении одной минуты на ваш номер поступит звонок введите последние 4 цифры этого номера
                </div>
              </div>
            )}
            <div className="form-group fv-plugins-icon-container has-danger">
              <label className="font-size-h6 font-weight-bolder text-dark">
                {' '}
                {checkCodeType === 'email' ? 'Код из письма' : 'Последний 4 цифры'}
              </label>
              <Field
                type="number"
                placeholder={checkCodeType === 'email' ? 'Введите код из письма' : 'Введите последний 4 цифры номера'}
                name="code"
                className={`form-control form-control-solid h-auto py-5 px-6 mr-2
                      ${(touched.code && errors.code && 'is-invalid') || (touched.code && !errors.code && 'is-valid')}`}
              />
              {touched.code && errors.code ? <div className="invalid-feedback">{errors.code}</div> : null}
            </div>
            <div className="text-left mb-5 mb-lg-5">
              <p className="font-weight-bold font-size-h8">
                Нажимая кнопку "Войти", Вы соглашаетесь с условиями{' '}
                <a target="_blank" href={PRIVACY}>
                  политики конфиденциальности
                </a>
                .
              </p>
            </div>
            <div className="pb-lg-0 pb-5">
              <button
                type="submit"
                disabled={isSubmitting}
                className="btn btn-primary font-weight-bolder font-size-lg px-8 my-3 mr-3"
              >
                <span>Войти</span>
                {loading && <span className="ml-3 spinner spinner-white"></span>}
              </button>
              <button
                type="button"
                onClick={() => backBtnHandler()}
                className="btn btn-light-primary font-weight-bolder px-8 my-3 font-size-lg"
              >
                Назад
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );

};
