/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from 'react';

export function ListsWidget9({ className, changeLog, version }) {
  return (
    <>
      <div className={`card card-custom ${className}`}>
        <div className="card-header align-items-center border-0 mt-4">
          <h3 className="card-title align-items-start flex-column">
            <span className="font-weight-bolder text-dark">Последние изменения</span>
            <span className="text-muted mt-3 font-weight-bold font-size-sm">{`Версия ${version.numb}, от ${version.date}`}</span>
          </h3>
        </div>
        <div className="card-body pt-4">
          <div className="timeline timeline-6 mt-3">
            {changeLog.map(change => (
              <div key={change.id} className="timeline-item align-items-start">
                <div className="timeline-label font-weight-bolder text-dark-75 font-size-lg">{change.dateClear}</div>

                <div className="timeline-badge">
                  <i className={`fa fa-genderless ${change.typeColor} icon-xl`}></i>
                </div>

                <div className="timeline-content d-flex">
                  <div className="row">
                    <div className="col-12">
                      <span className="font-weight-bolder text-dark-75 pl-3 font-size-lg">{change.title}</span>
                    </div>
                    <div className="col-12">
                      <div className="font-weight-mormal font-size-lg timeline-content text-muted pl-3">
                        {change.text}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
