import React from 'react';
import { Modal } from 'react-bootstrap';
import { shallowEqual, useSelector } from 'react-redux';

import { MyObjectsMapContent } from './MyObjectsMapContent';

export const MyObjectsMapDialog = ({ show, onHide }) => {
  const { currentState } = useSelector(state => ({ currentState: state.myObjects }), shallowEqual);
  const { allEntities } = currentState;

  return (
    <Modal size="lg" show={show} onHide={onHide}>
      {allEntities && <MyObjectsMapContent onHide={onHide} entities={allEntities} />}
    </Modal>
  );
}