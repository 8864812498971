import { createSlice } from '@reduxjs/toolkit';

const initialCalculationTO3State = {
  listLoading: false,
  actionsLoading: false,
  saveCalcLoading: false,
  lastError: null,
  attachId: [],
  saveCalculationNum: null,
  saveResponseType: '',
  entities: null,
  userSavedEntitiesIds: [],
  errorsList: null,
  responseType: null,
};
export const callTypes = {
  list: 'list',
  action: 'action',
  saveCalc: 'save',
};

export const calculationTO3Slice = createSlice({
  name: 'calculationTO3',
  initialState: initialCalculationTO3State,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;

      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
        state.actionsLoading = false;
      }
      if (action.payload.callType === callTypes.action) {
        state.actionsLoading = false;
      }
      if (action.payload.callType === callTypes.saveCalc) {
        state.saveCalcLoading = false;
        state.saveResponseType = 'failure';
      }
    },
    startCall: (state, action) => {
      state.error = null;
      state.attachId = null;
      state.saveCalculationNum = null;
      state.saveResponseType = null;
      state.responseType = null;

      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
        return;
      }
      if (action.payload.callType === callTypes.action) {
        state.actionsLoading = true;
        return;
      }
      if (action.payload.callType === callTypes.saveCalc) {
        state.saveCalcLoading = true;
        return;
      }
    },

    calculationTO3Fetched: (state, action) => {
      const { data } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.userSavedEntitiesIds = initialCalculationTO3State.userSavedEntitiesIds;
      if (data.type === 'success') {
        state.entities = [...data.entities];
        state.responseType = data.type;
        return;
      }

      if (data.type === 'error') {
        state.entities = [];
        state.responseType = data.type;

        state.errorsList = { ...data.error };
        return;
      }

      if (data.type === 'warning') {
        state.entities = [];
        state.responseType = data.type;

        state.errorsList = data.warning;
        return;
      }

      if (data.type === 'empty') {
        state.entities = [];
        state.responseType = data.type;
        state.errorsList = null;
        return;
      }
    },

    saveCalculationTO3Fetched: (state, action) => {
      const { data } = action.payload;
      state.saveCalcLoading = false;
      state.error = null;

      if (data.type === 'success') {
        state.attachId = [...data.aid];
        state.saveResponseType = data.type;
        state.saveCalculationNum = data.request;
        return;
      }
    },

    clearSaveResponseType: state => {
      state.saveResponseType = initialCalculationTO3State.saveResponseType;
      state.attachId = initialCalculationTO3State.attachId;
      state.saveCalculationNum = initialCalculationTO3State.saveCalculationNum;
    },

    saveSavedCalcId: (state, action) => {
      state.userSavedEntitiesIds = [...state.userSavedEntitiesIds, action.payload.id];
    },
  },
});
