import { createSlice } from '@reduxjs/toolkit';

const initialMyObjectsState = {
  listLoading: false,
  listAllLoading: false,
  objectSaving: false,
  totalCount: 0,
  entities: null, //with filter
  allEntities: [],
  modalData: null,
  lastCreatedObjId: null,
  myObjectForEdit: undefined,
  lastError: null,
  error: null,
};

export const callTypes = {
  list: 'list',
  listAll: 'listAll',
  action: 'action',
  saveObject: 'saveObject',
};

export const myObjectsSlice = createSlice({
  name: 'myObjects',
  initialState: initialMyObjectsState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
        return;
      }

      if (action.payload.callType === callTypes.listAll) {
        state.listAllLoading = false;
        return;
      }

      if (action.payload.callType === callTypes.saveObject) {
        state.objectSaving = false;
      }
    },

    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
        return;
      }

      if (action.payload.callType === callTypes.listAll) {
        state.listAllLoading = true;
        return;
      }

      if (action.payload.callType === callTypes.saveObject) {
        state.objectSaving = true;
        state.modalData = null;
      }
    },

    allMyObjectsFetched: (state, action) => {
      const { entities } = action.payload;
      state.listAllLoading = false;
      state.error = null;
      state.allEntities = entities;
    },

    myObjectsFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },

    saveMyObjectsFetched: (state, action) => {
      const { title, type, text, id } = action.payload;
      state.objectSaving = false;
      state.error = null;
      state.lastCreatedObjId = id;
      state.modalData = {
        text: text,
        title: title,
        type: type,
      };
    },

    clearSaveResponse: state => {
      state.modalData = null;
    },

    clearLastCreatedObjectId: state => {
      state.lastCreatedObjId = initialMyObjectsState.lastCreatedObjId;
    },
  },
});
