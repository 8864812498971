import store from '../../../../redux/store';
const {
  REACT_APP_MODE,
  REACT_APP_REQ_DEV,
  REACT_APP_REQ_PROD,
  REACT_APP_REQ_PROD_API,
  REACT_APP_REQ_DEV_API,
  REACT_APP_API_VERSION,
} = process.env;
const ENDPOINT = REACT_APP_MODE === 'dev' ? REACT_APP_REQ_DEV : REACT_APP_REQ_PROD;
const ENDPOINT_API = REACT_APP_MODE === 'dev' ? REACT_APP_REQ_DEV_API : REACT_APP_REQ_PROD_API;

// const SEND_CODE_URL = `${ENDPOINT}api/app/auth.login?`;
// const CHECK_CODE_URL = `${ENDPOINT}api/app/auth.send?`;

const GET_SETTINGS_URL = `${ENDPOINT}api/app/request.const`;
const CHANGE_PASS = `${ENDPOINT}api.php?type=app&method=changePassword&`;
const CHANGE_USER_INFO = `${ENDPOINT}api.php?type=app&method=userInfo&`;

const ENDPOINT_URL = `${ENDPOINT}profile/endpoint`;
const MY_LOGIN_URL = `${ENDPOINT_API}${REACT_APP_API_VERSION}/profile/login`;
const MY_PROFILE_URL = `${ENDPOINT_API}${REACT_APP_API_VERSION}/profile`;
const REGISTER = `${ENDPOINT_API}${REACT_APP_API_VERSION}/profile/register`;
const GET_CODE_URL = `${ENDPOINT_API}${REACT_APP_API_VERSION}/profile/code/send`;
const CHECK_CODE_URL = `${ENDPOINT_API}${REACT_APP_API_VERSION}/profile/code/check`;
const RECOVERY_URL = `${ENDPOINT_API}${REACT_APP_API_VERSION}/profile/recovery`;

//регистрация
export const register = async queryParams => {
  const formData = new FormData();
  for (let key in queryParams) {
    formData.append(key, queryParams[key]);
  }
  return await fetch(`${REGISTER}`, {
    method: 'POST',
    body: formData,
    headers: {
      Accept: 'application/json',
    },
  }).then(result => result.json());
};

//авторизация по логину и паролю
export async function auth_password(queryParameters) {
  const { login, password } = queryParameters;
  const formData = new FormData();
  formData.append('login', login);
  formData.append('password', password);
  return await fetch(`${MY_LOGIN_URL}`, {
    method: 'POST',
    body: formData,
    headers: {
      Accept: 'application/json',
    },
  }).then(response => response.json());
}

//сквозная авторизация
export const endpoint = async () => {
  return await fetch(`${ENDPOINT_URL}`, {
    credentials: 'include',
    headers: {
      Accept: 'application/json',
    },
  })
    .then(response => {
      if (response.ok) {
        return response.json();
      }
      return Promise.reject(response);
    })
    .catch(response => {
      return 'error';
    });
};

//запрос кода авторизации
export const get_code = async queryParams => {
  const formData = new FormData();
  for (let key in queryParams) {
    formData.append(key, queryParams[key]);
  }
  return await fetch(`${GET_CODE_URL}`, {
    method: 'POST',
    body: formData,
    headers: {
      Accept: 'application/json',
    },
  }).then(result => result.json());
};

//проверка кода авторизации
export const check_code = async queryParams => {
  const { session, checkCodeLogin, checkCodeType } = store.getState().auth;
  const { code } = queryParams;
  const formData = new FormData();
  formData.append('session', session);
  formData.append('type', checkCodeType);
  formData.append('code', code);
  formData.append('login', checkCodeLogin);
  return await fetch(`${CHECK_CODE_URL}`, {
    method: 'POST',
    body: formData,
    headers: {
      Accept: 'application/json',
    },
  }).then(result => result.json());
};

//Получение пользователя по токену
export async function getUserByToken() {
  const token = store.getState().auth.authToken;
  const response = await fetch(`${MY_PROFILE_URL}`, {
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  }).then(resp => resp.json());
  return response;
}

//восстановление пароля
export const recovery = async queryParams => {
  const { login } = queryParams;
  const formData = new FormData();
  formData.append('login', login);
  return await fetch(`${RECOVERY_URL}`, {
    method: 'POST',
    body: formData,
    headers: {
      Accept: 'application/json',
    },
  }).then(result => result.json());
};

export const getSettings = () => {
  const token = store.getState().auth.authToken;
  return fetch(`${GET_SETTINGS_URL}?&token=${token}`).then(response => {
    if (response.ok) {
      return response.json();
    }
    throw new Error();
  });
};

export function checkPassword(password) {
  const token = store.getState().auth.authToken;
  return fetch(`${MY_LOGIN_URL}?type=app&method=passwordCheck&password=${btoa(password)}&token=${token}`, {
    method: 'POST',
    // body: formData,
  })
    .then(resp => {
      if (resp.ok) {
        return resp.json();
      } else {
        throw new Error();
      }
    })
    .then(respJson => {
      return respJson.success;
    });
}

export const changePass = ({ currentPassword, password, cPassword }) => {
  const token = store.getState().auth.authToken;

  return fetch(
    `${CHANGE_PASS}token=${token}&oldPassword=${btoa(currentPassword)}&newPassword=${btoa(
      password,
    )}&verifyPassword=${btoa(cPassword)}`,
  ).then(response => {
    if (response.ok) {
      return response.json();
    }
    throw new Error();
  });
};

export const changeUserInfo = async (
  { name, surname, ochestvo, sex, birthday, phone, email },
  formAddress,
  formData,
) => {
  const token = store.getState().auth.authToken;

  return fetch(
    `${CHANGE_USER_INFO}token=${token}&name=${name}&surname=${surname}&ochestvo=${ochestvo}&sex=${sex}&birthday=${birthday}&place=${formAddress}&phone=${phone}&email=${email}`,
    {
      method: 'POST',
      body: formData,
    },
  ).then(response => {
    if (response.ok) {
      return response.json();
    }
    throw new Error();
  });
};
