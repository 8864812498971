/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback } from 'react';
import { useHistory } from 'react-router';
import { toAbsoluteUrl } from '../../../_helpers';

export function TilesWidget13({
  className,
  widgetHeight = '175px',
  row1 = 'Create SaaS',
  row2 = 'Based Reports',
  btnTitle = 'Create Report',
  href = '',
}) {
  const backgroundImageUrl = toAbsoluteUrl('/media/svg/patterns/taieri.svg');
  const history = useHistory();
  const redirect = useCallback(() => {
    history.push(href);
  }, [history, href]);

  return (
    <>
      <div
        className={`card card-custom bgi-no-repeat ${className}`}
        style={{
          height: widgetHeight,
          backgroundColor: '#663259',
          backgroundPosition: 'calc(100% + 0.5rem) 100%',
          backgroundSize: '100% auto',
          backgroundImage: `url("${backgroundImageUrl}")`,
        }}
        onClick={() => redirect()}
      >
        <div className="card-body d-flex align-items-center cursor-pointer">
          <div>
            <h3 className="text-white font-weight-bolder line-height-lg mb-5">
              {row1} <br /> {row2}
            </h3>
            <a onClick={() => redirect()} className="btn btn-primary font-weight-bold px-6 py-3">
              {btnTitle}
            </a>
          </div>
        </div>
      </div>
    </>
  );
}
