export const ROUTES = {
  HOME: '/home',
  E_COMMERCE: '/e-commerce',
  NOTIFI: '/notifications',
  PROMOTIONS: '/promotions',
  PROMOTION: '/promotions/:id',
  ORDER: '/e-commerce/orders/:id/order',
  ORDERS: '/e-commerce/orders',
  ORDERS_ARCHIVE: '/e-commerce/orders-archive',
  DESIGN: '/e-commerce/design',
  CALCULATIONS: '/e-commerce/design/calculations',
  CALCULATION: '/e-commerce/design/calculations/:id/calculation',
  NEW_CALC: '/e-commerce/design/new-calculation',
  NEW_TO: '/e-commerce/design/new-calculation/to',
  NEW_BTP: '/e-commerce/design/new-calculation/btp',
  NEW_BTP_PROJECT: '/e-commerce/design/new-calculation/btp/:id/project',
  NEW_BTP_FREE_FORM: '/e-commerce/design/new-calculation/btp/free',
  NEW_TO1: '/e-commerce/design/new-calculation/to/to1',
  NEW_TO2: '/e-commerce/design/new-calculation/to/to2',
  NEW_TO3: '/e-commerce/design/new-calculation/to/to3',
  NEW_PUMP: `/e-commerce/design/new-calculation/pump`,
  NEW_PUMP_STATION: '/e-commerce/design/new-calculation/station',
  NEW_FLOW_METER: '/e-commerce/design/new-calculation/flow-meter',
  USER: '/user-info',
  USER_PROFILE: '/user-info/profile',
  USER_PROFILE_INFO: '/user-info/profile/personal-information',
  USER_PROFILE_PASS: '/user-info/profile/change-password',
  USER_PROFILE_BUILDER: '/user-info/profile/builder',
  USER_ORGANISATIONS: '/user-info/my-organisations',
  USER_OBJECTS: '/user-info/my-objects',
  SUPPORT: '/support',
  SUPPORT_REPORTS: '/support/reports',
  TOOLS: '/tools',
  TOOLS_SCANNER: '/tools/scanner',

  TOOLS_SCADA_LIST: '/tools/scada',
  TOOLS_SCADA_SHOW: '/tools/scada/:id',

  AUTH: '/auth',
  AUTH_FAST: '/auth/fast',
  AUTH_REG: '/auth/registration',
  AUTH_CHECK: '/auth/code',
  AUTH_LOGIN: '/auth/login',
  AUTH_FORGOT: '/auth/forgot-password',

  LOGOUT: '/logout',
  ERROR: '/error',
};
