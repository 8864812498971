import * as requestFromServer from './authCrud';
import { authSlice, callTypes } from './authSlice';

const { actions } = authSlice;

export const disableStartInfoTooltip = () => dispatch => {
  return dispatch(actions.disableStartInfoTooltip());
};

//регистрация
export const fetchRegister = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.register }));
  return requestFromServer
    .register(queryParams)
    .then(response => {
      if (typeof response.access_token == 'undefined') {
        throw response;
      }
      dispatch(actions.registerFetched(response));
    })
    .catch(error => {
      error.clientMessage = error.message;
      dispatch(actions.catchError({ error, callType: callTypes.register }));
      throw error;
    });
};

//авторизация по логину и паролю
export const fetchToken = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.auth_password }));
  return requestFromServer
    .auth_password(queryParams)
    .then(response => {
      if (typeof response.access_token == 'undefined') {
        throw response;
      }
      dispatch(actions.tokenFetched(response));
    })
    .catch(error => {
      error.clientMessage = error.message;
      dispatch(actions.catchError({ error, callType: callTypes.auth_password }));
      throw error;
    });
};

//сквозная авторизация
export const fetchEndPoint = () => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.register }));
  return requestFromServer
    .endpoint()
    .then(response => {
      if (typeof response == 'undefined') {
        throw response;
      }
      if (typeof response.access_token == 'undefined') {
        throw response;
      }
      dispatch(actions.endPointFetched(response));
    })
    .catch(error => {
      throw error;
    });
};

//сквозная авторизация вход
export const endPointLogin = token => dispatch => {
  return dispatch(actions.endPointLogin(token));
};

//отправка кода для авторизации
export const fetchGetCode = queryParameters => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.getCode }));
  return requestFromServer
    .get_code(queryParameters)
    .then(response => {
      if (typeof response == 'undefined') {
        throw response;
      }
      if (typeof response.send_type == 'undefined') {
        throw response;
      }
      dispatch(actions.CheckCodeFetched(response));
    })
    .catch(error => {
      error.clientMessage = "Can't check code!";
      dispatch(actions.catchError({ error, callType: callTypes.getCode }));
      throw error;
    });
};

//проверка кода для авторизации
export const registerByCode = queryParameters => dispatch => {
  return dispatch(sendUserCode(queryParameters))
    .then(() => dispatch(fetchUser()))
    .catch(error => {
      error.clientMessage = "Can't register by code!";
      throw new Error(error.message);
    });
};

export const sendUserCode = queryParameters => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.sendCode }));
  return requestFromServer.check_code(queryParameters).then(response => {
    if (response.type === 'error') {
      throw new Error(response.error);
    }
    dispatch(actions.codeSended(response));
  });
};

//восстановление пароля
export const fetchRecovery = queryParameters => dispatch => {
  return dispatch(sendRecovery(queryParameters))
    .then(response => {
      console.log(response);
      return response;
    })
    .catch(error => {
      console.log(error);
      throw error;
    });
};

export const sendRecovery = queryParameters => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.sendCode }));
  return requestFromServer
    .recovery(queryParameters)
    .then(response => {
      if (typeof response.errors != 'undefined') {
        console.log(response);
        throw response;
      }
      
      dispatch(actions.codeSended(response));
      return response;
    });
};

export const fetchUser = () => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.getUser }));
  return requestFromServer
    .getUserByToken()
    .then(response => {
      dispatch(actions.userFetched(response));
    })
    .catch(error => {
      error.clientMessage = "Can't get user!";
      dispatch(actions.catchError({ error, callType: callTypes.getUser }));
    });
};

export const logout = () => dispatch => {
  return dispatch(actions.logoutUser());
};

export const setUser = user => dispatch => {
  return dispatch(actions.setUserData(user));
};

export const clearCheckCodeState = () => dispatch => {
  return dispatch(actions.clearCheckCode());
};
