import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Card, CardBody, CardFooter, CardHeader } from '../../../../../../_metronic/_partials/controls';
import { ScadaListTable } from './components/ScadaListTable';
import * as orgActions from './../../../../UserInfo/_redux/myOrganisations/myOrganisationsActions';
import * as objActions from './../../../../UserInfo/_redux/myObjects/myObjectsActions';
import { ScadaListFilter } from './components/ScadaListFilter';

export const ScadaListCard = () => {
  const dispatch = useDispatch();

  const { currentState } = useSelector(state => ({ currentState: state.scada }));
  const { scadaListEntities, scadaListTotalCount, scadaListLoading } = currentState;

  useEffect(() => {
    dispatch(objActions.getAllMyObjects());
    dispatch(orgActions.getAllMyOrganisations());
  }, []);

  return (
    <Card>
      <CardHeader title="Диспетчеризация" />
      <CardBody>
        <ScadaListFilter />
        <ScadaListTable entities={scadaListEntities} totalCount={scadaListTotalCount} loading={scadaListLoading} />
      </CardBody>
      <CardFooter />
    </Card>
  );
};
