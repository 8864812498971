import store from '../../../../../redux/store';

const { REACT_APP_MODE, REACT_APP_REQ_DEV, REACT_APP_REQ_PROD } = process.env;
const ENDPOINT = REACT_APP_MODE === 'dev' ? REACT_APP_REQ_DEV : REACT_APP_REQ_PROD;

export const ORDERS_URL = `${ENDPOINT}api/app/sales.get?`;
export const ORDERS_SAVE = `${ENDPOINT}api/app/sales.store?`;
export const ORDERS_ADD_TO_ARCHIVE = `${ENDPOINT}api/app/sales.update_no_protect?`;

export async function findActualOrders(queryParams) {
  const token = store.getState().auth.authToken;
  const filter = JSON.stringify(queryParams.filter);

  return fetch(
    `${ORDERS_URL}search=${queryParams.searchText}&filter=${filter}&pageSize=${queryParams.pageSize}&pageNumber=${queryParams.pageNumber}&sortField=${queryParams.sortField}&sortOrder=${queryParams.sortOrder}&token=${token}`,
  ).then(res => res.json());
}

export async function findArchiveOrders(queryParams) {
  const token = store.getState().auth.authToken;
  const filter = JSON.stringify(queryParams.filter);

  return fetch(
    `${ORDERS_URL}search=${queryParams.searchText}&filter=${filter}&pageSize=${queryParams.pageSize}&pageNumber=${queryParams.pageNumber}&sortField=${queryParams.sortField}&sortOrder=${queryParams.sortOrder}&token=${token}&archive=1`,
  ).then(res => res.json());
}

export async function saveNewOrder(queryParams) {
  const token = store.getState().auth.authToken;
  const formData = new FormData();
  for (let key in queryParams) {
    formData.append(key, queryParams[key]);
  }

  return fetch(`${ORDERS_SAVE}&token=${token}`, {
    method: 'POST',
    body: formData,
  }).then(res => res.json());
}

export async function findAllOrders(queryParams) {
  const token = store.getState().auth.authToken;
  const filter = JSON.stringify(queryParams.filter);

  return fetch(
    `${ORDERS_URL}filter=${filter}&sortField=${queryParams.sortField}&sortOrder=${queryParams.sortOrder}&token=${token}`,
  ).then(res => res.json());
}

export async function addToArchive(queryParams) {
  const token = store.getState().auth.authToken;
  const formData = new FormData();
  for (let key in queryParams) {
    formData.append(key, queryParams[key]);
  }

  return fetch(`${ORDERS_ADD_TO_ARCHIVE}&token=${token}&archive=1`, {
    method: 'POST',
    body: formData,
  }).then(res => res.json());
}

export async function getFromArchive(queryParams) {
  const token = store.getState().auth.authToken;
  const formData = new FormData();
  for (let key in queryParams) {
    formData.append(key, queryParams[key]);
  }

  return fetch(`${ORDERS_ADD_TO_ARCHIVE}&token=${token}&archive=0`, {
    method: 'POST',
    body: formData,
  }).then(res => res.json());
}