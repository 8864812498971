import store from '../../../../../redux/store';

const { REACT_APP_MODE, REACT_APP_REQ_DEV, REACT_APP_REQ_PROD } = process.env;
const ENDPOINT = REACT_APP_MODE === 'dev' ? REACT_APP_REQ_DEV : REACT_APP_REQ_PROD;

const OBJECTS_URL = `${ENDPOINT}api/app/ObjectApi.get?`;
const OBJECTS_CREATE_URL = `${ENDPOINT}api/app/ObjectApi.store?`;

export async function findMyObjects(queryParams) {
  const token = store.getState().auth.authToken;
  const filter = JSON.stringify(queryParams.filter);

  return fetch(
    `${OBJECTS_URL}search=${queryParams.searchText}&filter=${filter}&pageSize=${queryParams.pageSize}&pageNumber=${queryParams.pageNumber}&sortField=${queryParams.sortField}&sortOrder=${queryParams.sortOrder}&token=${token}`,
  ).then(res => res.json());
}

export async function getAllMyObjects() {
  const token = store.getState().auth.authToken;
  return fetch(`${OBJECTS_URL}token=${token}`).then(res => res.json());
}

export const saveMyObjects = queryParams => {
  const token = store.getState().auth.authToken;
  return fetch(
    `${OBJECTS_CREATE_URL}name=${queryParams.name}&coords=${queryParams.coords}&address=${queryParams.address}&token=${token}`,
  ).then(res => res.json());
};
