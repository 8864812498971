import PerfectScrollbar from "react-perfect-scrollbar";
import React from "react";

import { NotifiWidgetRow } from "./NotifiWidgetRow";
import { Card, CardBody, CardHeader } from "../../../_metronic/_partials/controls";

export const NotifiWidget = ({ className, title, entities }) => {
  const notifiLength = 4;

  const perfectScrollbarOptions = {
    wheelSpeed: 2,
    wheelPropagation: false,
  };

  return (
    <>
      <Card className={className}>
        <CardHeader>
          <h3 className="card-title align-items-start flex-column text-dark my-5">
            <span className="font-weight-bolder text-dark">{title}</span>
            <span className="text-muted mt-3 font-weight-bold font-size-sm">Последние уведомления</span>
          </h3>
        </CardHeader>
        <CardBody>
          <PerfectScrollbar
            options={perfectScrollbarOptions}
            className="navi navi-hover scroll"
            style={{ maxHeight: "200px", position: "relative" }}
          >
            {entities && entities.map((entity, entityIndex) => entityIndex > notifiLength ? <div key={entity.id}></div> : <NotifiWidgetRow key={entity.id} entity={entity}/>)}
          </PerfectScrollbar>
        </CardBody>
      </Card>
    </>
  );
}

