export const timeOfDay = () => {
  const hoursNow = new Date().getHours();
  if (hoursNow >= 0 && hoursNow <= 3) {
    return 'Доброй ночи';
  }

  if (hoursNow >= 4 && hoursNow <= 11) {
    return 'Доброе утро';
  }

  if (hoursNow >= 12 && hoursNow <= 16) {
    return 'Добрый день';
  }

  if (hoursNow >= 17 && hoursNow <= 23) {
    return 'Добрый день';
  }
};

export const getDateTime = time => {
  const myTime = new Date(time);
  return `${myTime.getFullYear()}-${myTime.getMonth() +
    1}-${myTime.getDate()} ${myTime.getHours()}:${myTime.getMinutes()}:${myTime.getSeconds()}`;
};
