import React, { useMemo, useRef } from 'react';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import 'yup-phone-lite';
import { PartySuggestions } from 'react-dadata';
import 'react-dadata/dist/react-dadata.css';

import { useRegistrationUIContext } from '../RegistrationUIContext';
import { initialFormsData, validFormsData } from '../RegistrationUIHelpers';
import { toAbsoluteUrl } from '../../../../../_metronic/_helpers';
import SVG from 'react-inlinesvg';

export const Step2 = () => {
  const formikRef = useRef(null);
  const registrationUIContext = useRegistrationUIContext();
  const registrationUIProps = useMemo(() => {
    return {
      validDataStep2: registrationUIContext.validDataStep2,
      setValidDataStep2: registrationUIContext.setValidDataStep2,
      formDataStep2: registrationUIContext.formDataStep2,
      setFormDataStep2: registrationUIContext.setFormDataStep2,
    };
  }, [
    registrationUIContext.validDataStep2,
    registrationUIContext.setValidDataStep2,
    registrationUIContext.formDataStep2,
    registrationUIContext.setFormDataStep2,
  ]);

  const onValidate = e => {
    registrationUIProps.setFormDataStep2({ ...registrationUIProps.formDataStep2, [e.target.name]: e.target.value });
    formikRef.current.setFieldValue(e.target.name, e.target.value).then(() => {
      registrationUIProps.setValidDataStep2(formikRef.current.isValid);
      validFormsData.step2 = formikRef.current.isValid;
    });
  };

  const onDaData = e => {
    registrationUIProps.setFormDataStep2({ ...registrationUIProps.formDataStep2, ['organization']: e.data.inn });
    registrationUIProps.setFormDataStep2({ ...registrationUIProps.formDataStep2, ['suggestion']: e });
    formikRef.current.setFieldValue('organization', e.data.inn).then(() => {
      registrationUIProps.setValidDataStep2(formikRef.current.isValid);
      validFormsData.step2 = formikRef.current.isValid;
    });
  };

  const Schema = Yup.object().shape({
    email: Yup.string()
      .email('Некорректный E-mail')
      .required('Поле обязательно для заполнения'),
    phone: Yup.string()
      .phone('RU', 'Некорректный телефон')
      .required('Поле обязательно для заполнения'),
    account_type: Yup.string(),
    post: Yup.string().when('account_type', {
      is: 'corporate',
      then: Yup.string().required('Поле обязательно для заполнения'),
      otherwise: Yup.string().notRequired(),
    }),
    organization: Yup.string().when('account_type', {
      is: 'corporate',
      then: Yup.string().required('Поле обязательно для заполнения'),
      otherwise: Yup.string().notRequired(),
    }),
  });

  return (
    <Formik
      innerRef={formikRef}
      initialValues={{
        email: registrationUIProps.formDataStep2.email,
        phone: registrationUIProps.formDataStep2.phone,
        post: registrationUIProps.formDataStep2.post,
        organization: registrationUIProps.formDataStep2.organization,
        account_type: registrationUIProps.formDataStep2.account_type,
      }}
      validationSchema={Schema}
    >
      {({ errors, touched}) => (
        <Form className="form form-label-right">
          <div className="row">
            <div className="col-md-6">
              <div className="form-group fv-plugins-icon-container has-danger">
                <label className="font-size-h6 font-weight-bolder text-dark">Ваш E-mail</label>
                <Field
                  type="text"
                  placeholder="Укажите ваш E-mail"
                  name="email"
                  onChange={e => onValidate(e)}
                  className={`form-control form-control-solid h-auto py-5 px-6 mr-2
                  ${(touched.email && errors.email && 'is-invalid') || (touched.email && !errors.email && 'is-valid')}`}
                />
                {touched.email && errors.email ? <div className="invalid-feedback">{errors.email}</div> : null}
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group fv-plugins-icon-container has-danger">
                <label className="font-size-h6 font-weight-bolder text-dark">Ваше телефон</label>
                <Field
                  type="text"
                  placeholder="Укажите ваш телефон"
                  name="phone"
                  onChange={e => onValidate(e)}
                  className={`form-control form-control-solid h-auto py-5 px-6 mr-2
                  ${(touched.phone && errors.phone && 'is-invalid') || (touched.phone && !errors.phone && 'is-valid')}`}
                />
                {touched.phone && errors.phone ? <div className="invalid-feedback">{errors.phone}</div> : null}
              </div>
            </div>

            <div className="col-lg-10 form-group fv-plugins-icon-container has-danger">
              <label className="font-size-h6 font-weight-bolder text-dark">Тип аккаунта</label>

              <div className="row">
                <div className="col-lg-6">
                  <Field
                    type="radio"
                    name="account_type"
                    id="account_type_personal"
                    value="personal"
                    onChange={e => onValidate(e)}
                    checked={registrationUIProps.formDataStep2.account_type == 'personal' ? true : false}
                    className={`btn-check`}
                  />

                  <label
                    className="btn btn-outline btn-outline-dashed btn-active-light-primary p-7 d-flex align-items-center"
                    htmlFor="account_type_personal"
                  >
                    <span className="mr-5">
                      <SVG src={toAbsoluteUrl('/media/svg/icons/Custom/Personal.svg')} />
                    </span>

                    <span className="d-block fw-semibold text-start">
                      <span className="text-gray-900 fw-bold d-block fs-4 mb-2">Персональный аккаунт</span>
                      <span className="text-muted fw-semibold fs-6">В дальнейшем вы можете добавить организацию</span>
                    </span>
                  </label>

                  <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback"></div>
                </div>

                <div className="col-lg-6  pt-5 pt-sm-0">
                  <Field
                    type="radio"
                    name="account_type"
                    id="account_type_corporate"
                    onChange={e => onValidate(e)}
                    value="corporate"
                    checked={registrationUIProps.formDataStep2.account_type == 'corporate' ? true : false}
                    className={`btn-check`}
                  />
                  <label
                    className="btn btn-outline btn-outline-dashed btn-active-light-primary p-7 d-flex align-items-center"
                    htmlFor="account_type_corporate"
                  >
                    <span className="mr-5">
                      <SVG src={toAbsoluteUrl('/media/svg/icons/Custom/Corporate.svg')} />
                    </span>

                    <span className="d-block fw-semibold text-start">
                      <span className="text-gray-900 fw-bold d-block fs-4 mb-2">Корпоративный аккаунт</span>
                      <span className="text-muted fw-semibold fs-6">Добавьте организацию на этапе регистрации</span>
                    </span>
                  </label>
                </div>
              </div>
            </div>
            {(registrationUIProps.formDataStep2.account_type == 'corporate' ? true : false) && (
              <>
                <div className="col-md-12">
                  <div className="form-group fv-plugins-icon-container has-danger">
                    <label className="font-size-h6 font-weight-bolder text-dark">Ваша организация</label>
                    <PartySuggestions
                      token="ddeafb9ff43e79578243d8f031f6e9d8b047ca0e"
                      count="3"
                      value={registrationUIProps.formDataStep2.suggestion}
                      onChange={e => onDaData(e)}
                      inputProps={{
                        className: `form-control form-control-solid h-auto py-5 px-6 mr-2
                        ${(touched.organization && errors.organization && 'is-invalid') ||
                          (touched.organization && !errors.organization && 'is-valid')}`,
                        placeholder: `Начните вводить ИНН или название организации`,
                      }}
                    />
                    {touched.organization && errors.organization ? (
                      <div className="invalid-feedback">{errors.organization}</div>
                    ) : null}
                    <Field type="hidden" name="organization" />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group fv-plugins-icon-container has-danger">
                    <label className="font-size-h6 font-weight-bolder text-dark">Ваша должность</label>
                    <Field
                      type="text"
                      placeholder="Укажите вашу должность"
                      name="post"
                      onChange={e => onValidate(e)}
                      className={`form-control form-control-solid h-auto py-5 px-6 mr-2
                  ${(touched.post && errors.post && 'is-invalid') || (touched.post && !errors.post && 'is-valid')}`}
                    />
                    {touched.post && errors.post ? <div className="invalid-feedback">{errors.post}</div> : null}
                  </div>
                </div>
              </>
            )}
          </div>
        </Form>
      )}
    </Formik>
  );
};
