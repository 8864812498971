import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { Link, useHistory } from 'react-router-dom';
import SVG from 'react-inlinesvg';
import Swal from 'sweetalert2';
import { Step1 } from './Registration/Step1';
import { Step2 } from './Registration/Step2';
import { Step3 } from './Registration/Step3';
import { HeadSteps } from './Registration/HeadSteps';
import * as action_login from './../_redux/authActions';
import { ROUTES } from '../../../Routes.models';
import { toAbsoluteUrl } from '../../../../_metronic/_helpers';
import { stepName } from './RegistrationUIHelpers';
import { useRegistrationUIContext } from './RegistrationUIContext';
import '../../../../_metronic/_assets/sass/pages/login/login-4.scss';

export const RegistrationCard = () => {
  const history = useHistory();
  const intl = useIntl();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const [step, setStep] = useState(0);

  const registrationUIContext = useRegistrationUIContext();
  const registrationUIProps = useMemo(() => {
    return {
      formDataStep1: registrationUIContext.formDataStep1,
      formDataStep2: registrationUIContext.formDataStep2,
      formDataStep3: registrationUIContext.formDataStep3,
      validDataStep1: registrationUIContext.validDataStep1,
      validDataStep2: registrationUIContext.validDataStep2,
      validDataStep3: registrationUIContext.validDataStep3,
    };
  }, [
    registrationUIContext.formDataStep1,
    registrationUIContext.formDataStep2,
    registrationUIContext.formDataStep3,
    registrationUIContext.validDataStep1,
    registrationUIContext.validDataStep2,
    registrationUIContext.validDataStep3,
  ]);

  const handleChangeStep = useCallback(() => {
    if (step < stepName.length - 1) {
      if (step === 0) {
        setStep(step + 1);
      }
      if (step === 1) {
        setStep(step + 1);
      }
      return;
    }
  }, [step]);

  const handleChangeStepBack = () => {
    if (step > 0) {
      setStep(step - 1);
    }
  };

  const handleRegister = () => {
    enableLoading();
    dispatch(
      action_login.fetchRegister({
        ...registrationUIProps.formDataStep1,
        ...registrationUIProps.formDataStep2,
        ...registrationUIProps.formDataStep3,
      }),
    )
      .then(() => {
        dispatch(action_login.fetchUser());
      })
      .catch(e => {
        Swal.fire({
          title: 'Ошибка',
          text: e.message,
          icon: 'error',
          confirmButtonText: 'Продолжить',
          heightAuto: false,
        });
        disableLoading();
      });
  };

  const isNextButtonDisabled = () => {
    if (step === 0) {
      return !registrationUIProps.validDataStep1;
    }

    if (step === 1) {
      return !registrationUIProps.validDataStep2;
    }

    if (step === 2) {
      return !registrationUIProps.validDataStep3;
    }
    return false;
  };

  const stepContent = useMemo(() => {
    return [<Step1 />, <Step2 />, <Step3 />][step];
  }, [step]);

  return (
    <>
      <div className="login-form login-signin registration" id="kt_login_signin_form">
        <h3 className="font-weight-bolder text-dark font-size-h2 font-size-h1-lg">Регистрация</h3>
        <div className="text-muted font-weight-bold font-size-h4">
          Уже есть аккаунт?&nbsp;
          <Link to={ROUTES.AUTH_FAST} className="text-primary font-weight-bolder">
            Войти
          </Link>
        </div>

        <HeadSteps step={step} />

        <div className="row justify-content-center pt-15 pt-sm-0">
          <div className="col-12">{stepContent}</div>
        </div>
        <div className="d-flex justify-content-between pt-7">
          <div className="mr-2">
            {step != 0 && (
              <button
                type="button"
                className="btn btn-light-primary font-weight-bolder font-size-h6 pr-8 pl-6 py-4 my-3 mr-3"
                onClick={handleChangeStepBack}
              >
                <span className={`svg-icon svg-icon pl-3 mr-2`}>
                  <SVG src={toAbsoluteUrl('/media/svg/icons/Navigation/Left-2.svg')}></SVG>
                </span>
                Назад
              </button>
            )}
          </div>

          <div>
            {step != 2 && (
              <button
                type="button"
                className="btn btn-primary font-weight-bolder font-size-h6 pl-8 pr-4 py-4 my-3"
                onClick={handleChangeStep}
                disabled={isNextButtonDisabled()}
              >
                Далее
                <span className={`svg-icon svg-icon pl-3 mr-2`}>
                  <SVG src={toAbsoluteUrl('/media/svg/icons/Navigation/Right-2.svg')}></SVG>
                </span>
              </button>
            )}
            {step == 2 && (
              <button
                type="button"
                className="btn btn-primary font-weight-bolder font-size-h6 pl-8 pr-4 py-4 my-3"
                onClick={handleRegister}
                disabled={isNextButtonDisabled()}
              >
                Зарегистрироваться
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
