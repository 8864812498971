import React from 'react';
import SVG from 'react-inlinesvg';

import { toAbsoluteUrl } from '../../../../../_metronic/_helpers';
import { stepDescription, stepName } from '../RegistrationUIHelpers';

export const HeadSteps = ({ step }) => {
  return (
    <>
      <div className="d-none d-sm-block wizard-nav pt-5 pt-lg-15 pb-10">
        <div className="wizard-steps d-flex flex-column flex-sm-row">
          {stepName.map((item, index) => {
            return (
              <div
              className="wizard-step flex-grow-1 flex-basis-0"
                key={index}
                data-wizard-type="step"
                data-wizard-state={index <= step ? 'current' : 'pending'}
              >
                <div className="wizard-wrapper pr-7">
                  <div className="wizard-icon">
                    <i className="wizard-check ki ki-check"></i>
                    <span className="wizard-number">{`${index + 1}`}</span>
                  </div>
                  <div className="wizard-label">
                    <h3 className="wizard-title">{`${item}`}</h3>
                    <div className="wizard-desc">{`${stepDescription[index]}`}</div>
                  </div>
                  <span className={`svg-icon pl-10 wizard-arrow ${index === stepName.length - 1 ? 'last' : ''}`}>
                    <SVG src={toAbsoluteUrl('/media/svg/icons/Navigation/Arrow-right.svg')}></SVG>
                  </span>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};
