import store from '../../../../../redux/store';

const { REACT_APP_MODE, REACT_APP_REQ_PROD_API, REACT_APP_REQ_DEV_API, REACT_APP_API_VERSION } = process.env;
const ENDPOINT_API = REACT_APP_MODE === 'dev' ? REACT_APP_REQ_DEV_API : REACT_APP_REQ_PROD_API;

const SCADA_GET_OBJECTS = `${ENDPOINT_API}${REACT_APP_API_VERSION}/scada`;
const SCADA_GET_OBJECT = `${ENDPOINT_API}${REACT_APP_API_VERSION}/scada`;
const SCADA_GET_OBJECT_VAR = `${ENDPOINT_API}${REACT_APP_API_VERSION}/scada/tags`;

export const getScadaList = async queryParams => {
  const token = store.getState().auth.authToken;
  let search = '';
  if (queryParams.searchText !== '') {
    search = search + queryParams.searchText;
    search = search + '&searchFields=title;address';
  } else {
    Object.keys(queryParams.filter).forEach(function(key) {
      if (queryParams.filter[key] !== '') {
        search = search + key + ':' + queryParams.filter[key].value + ';';
      }
    });
    search = search + '&searchJoin=and';
  }
  return await fetch(
    `${SCADA_GET_OBJECTS}?search=${search}&limit=${queryParams.pageSize}&page=${queryParams.pageNumber}&orderBy=${queryParams.sortField}&sortedBy=${queryParams.sortOrder}`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
    },
  ).then(result => result.json());
};

export const getScadaShow = async queryParams => {
  const token = store.getState().auth.authToken;

  return await fetch(`${SCADA_GET_OBJECT}/${queryParams.id}`, {
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  }).then(result => result.json());
};

export const getScadaShowVar = async queryParams => {
  const token = store.getState().auth.authToken;

  return await fetch(`${SCADA_GET_OBJECT_VAR}/${queryParams.id}`, {
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  }).then(result => result.json());
};
