/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

export function TilesWidget10({
  className,
  widgetHeight = '175px',
  title = 'Create CRM Reports',
  discription = 'Generate the latest CRM Report',
  btnTitle = 'Start Now',
  href = 'http://wiki.atri-energo.ru/doku.php?id=lk',
}) {
  return (
    <>
      <div className={`card card-custom ${className}`} style={{ height: widgetHeight }}>
        <div className="card-body d-flex align-items-center justify-content-between flex-wrap">
          <div className="mr-2">
            <h3 className="font-weight-bolder">{title}</h3>
            <p className="text-dark-50 font-size-lg mt-2">{discription}</p>
          </div>
          <a
            href={href}
            target="_blank"
            rel="noopener noreferrer"
            className="btn btn-primary font-weight-bold py-3 px-6"
          >
            {btnTitle}
          </a>
        </div>
      </div>
    </>
  );
}
