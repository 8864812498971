import React from 'react';
import { useIntl } from "react-intl";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";
import { NotificationsFilter } from './notifications-filter/NotificationsFilter';
import { NotificationsTable } from './notifications-table/NotificationsTable';

export const NotificationsCard = () => {
  const intl = useIntl();

  return (
    <Card>
      <CardHeader title={intl.formatMessage({ id: "HEADER_ASIDE.NOTIFY" })}>
        <CardHeaderToolbar />
      </CardHeader>
      <CardBody>
        <NotificationsFilter />
        <NotificationsTable />
      </CardBody>
    </Card>
  );
};