import { createSlice } from '@reduxjs/toolkit';

const initialScadaState = {
  scadaListLoading: false,
  scadaListTotalCount: 0,
  scadaListEntities: [],

  scadaShowLoading: false,
  scadaShowVarLoading: false,
  scadaShowEntity: {},
  scadaShowVarEntities: [],
  error: null,
};
export const callTypes = {
  getScadaList: 'getScadaList',
  getScadaShow: 'getScadaShow',
  getScadaShowVar: 'getScadaShowVar',
};

export const scadaSlice = createSlice({
  name: 'scada',
  initialState: initialScadaState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.getScadaList) {
        state.scadaListLoading = false;
      }

      if (action.payload.callType === callTypes.getScadaShow) {
        state.scadaShowLoading = false;
      }

      if (action.payload.callType === callTypes.getScadaShowVar) {
        state.scadaShowVarLoading = false;
      }
    },

    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.getScadaList) {
        state.scadaListLoading = true;
      }

      if (action.payload.callType === callTypes.getScadaShow) {
        state.scadaShowLoading = true;
      }

      if (action.payload.callType === callTypes.getScadaShowVar) {
        state.scadaShowVarLoading = true;
      }
    },

    allScadaListFetched: (state, action) => {
      const { entities, totalCount } = action.payload;

      state.scadaListLoading = false;
      state.error = null;
      state.scadaListEntities = entities;
      state.scadaListTotalCount = totalCount;
      state.error = null;
    },

    scadaShowFetched: (state, action) => {
      const { entity } = action.payload;
      state.scadaShowLoading = false;
      state.scadaShowEntity = entity;
      state.error = null;
    },

    scadaShowVarFetched: (state, action) => {
      const { entities } = action.payload;
      state.scadaShowVarLoading = false;
      state.scadaShowVarEntities = entities;
      state.error = null;
    },
  },
});
