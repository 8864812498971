import { createSlice } from '@reduxjs/toolkit';

const initialCalculationState = {
  loading: false,
  listLoading: false,
  actionsLoading: false,
  entities: null,
  commercOfferEntities: null,
  commercOfferType: null,
  calcType: null,
  solutionType: null,
  lastError: null,
  error: null,
};

export const callTypes = {
  list: 'list',
  action: 'action',
};

export const calculationSlice = createSlice({
  name: 'calculation',
  initialState: initialCalculationState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      state.loading = false;
    },
    startCall: state => {
      state.error = null;
      state.loading = true;
    },

    calculationFetched: (state, action) => {
      const { data } = action.payload;
      state.loading = false;
      state.error = null;
      state.calcType = data.type;
      state.entities = data;
      state.solutionType = data.solutionType;
    },

    saveCommercOfferFetched: (state, action) => {
      const { data } = action.payload;
      state.commercOfferEntities = data;
      state.commercOfferType = data.type;
      state.loading = false;
      state.error = null;
    },

    clearOffer: state => {
      state.commercOfferEntities = null;
      state.commercOfferType = null;
    },
  },
});
