import React, { useEffect, useMemo, useState } from 'react';
import { NavDropdown } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import useWebSocket from 'react-use-websocket';

import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardHeaderToolbar,
} from '../../../../../../_metronic/_partials/controls';
import { CardSpinner } from '../../../../../widgets/cardSpinner/CardSpinner';
import * as actions from './../../../redux/scada/ScadaActions';
import { ScadaShowVarTable } from './components/ScadaShowVarTable';
import { ScadaShowProject } from './components/ScadaShowProject';
import { ScadaShowChart } from './components/ScadaShowChart';

const TAB_NAMES = {
  project: 0,
  variables: 1,
  trends: 2,
  reports: 3,
};

export const ScadaShowCard = ({ id }) => {
  const [tableData, setTableData] = useState([]);
  const [selectedTab, setSelectedTab] = useState(TAB_NAMES.project);

  const { currentState } = useSelector(state => ({ currentState: state.scada }));
  const { token } = useSelector(state => ({ token: state.auth.authToken }));

  const { scadaShowEntity, scadaShowLoading, scadaShowVarEntities } = currentState;

  const dispatch = useDispatch();

  const SOCKET_URL = `wss://atri-energo.ru/websocket?token=${token}&wss_type=scada&scada_id=${scadaShowEntity.id}`;

  const { lastMessage } = useWebSocket(SOCKET_URL);

  useEffect(() => {
    dispatch(actions.getScadaShow({ id: id }));
    dispatch(actions.getScadaShowVar({ id: id }));
  }, []);

  useEffect(() => {
    if (!lastMessage?.data) {
      return;
    }

    const socetData = JSON.parse(lastMessage?.data);
    const newTableData = scadaShowVarEntities.map((item, index) => {
      return { ...item, socet: socetData[index] };
    });

    setTableData(newTableData);
  }, [scadaShowVarEntities, lastMessage]);

  const currentTab = useMemo(() => {
    return [
      <ScadaShowProject id={id} />,
      <ScadaShowVarTable scadaShowVarEntities={tableData} />,
      <ScadaShowChart id={id} />,
      'Раздел находится в разработке!',
    ][selectedTab];
  }, [selectedTab, tableData]);

  return (
    <>
      {scadaShowLoading && <CardSpinner />}
      <Card>
        <CardHeader
          headerClassName="card-header-tabs-line"
          title={`Объект диспетчеризации: ${scadaShowEntity.title ? scadaShowEntity.title : ''}`}
        >
          <CardHeaderToolbar>
            <ul className="nav nav-tabs nav-bold nav-tabs-line">
              <li className="nav-item">
                <span
                  className={`nav-link cursor-pointer ${selectedTab === TAB_NAMES.project ? 'active' : ''}`}
                  onClick={() => setSelectedTab(TAB_NAMES.project)}
                >
                  <span className="nav-icon">
                    <i className="flaticon2-chat-1"></i>
                  </span>
                  <span className="nav-text">Проект</span>
                </span>
              </li>
              <span className="nav-item">
                <span
                  className={`nav-link cursor-pointer ${selectedTab === TAB_NAMES.variables ? 'active' : ''}`}
                  onClick={() => setSelectedTab(TAB_NAMES.variables)}
                >
                  <span className="nav-icon">
                    <i className="flaticon2-drop"></i>
                  </span>
                  <span className="nav-text">Переменные</span>
                </span>
              </span>
              <span className="nav-item">
                <span
                  className={`nav-link cursor-pointer ${selectedTab === TAB_NAMES.trends ? 'active' : ''}`}
                  onClick={() => setSelectedTab(TAB_NAMES.trends)}
                >
                  <span className="nav-icon">
                    <i className="flaticon2-drop"></i>
                  </span>
                  <span className="nav-text">Тренды</span>
                </span>
              </span>
              <NavDropdown
                as={'li'}
                title={
                  <>
                    <span className="nav-icon">
                      <i className="flaticon2-gear"></i>
                    </span>
                    <span className="nav-text">Отчеты</span>
                  </>
                }
              >
                <NavDropdown.Item>Отчет 1</NavDropdown.Item>
                <NavDropdown.Item>Отчет 2</NavDropdown.Item>
                <NavDropdown.Item>Отчет 3</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item>Все отчеты</NavDropdown.Item>
              </NavDropdown>
            </ul>
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>{currentTab}</CardBody>
        <CardFooter />
      </Card>
    </>
  );
};
