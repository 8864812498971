import React from "react";
import { Route } from "react-router";
import { ROUTES } from "../../../../Routes.models";
import { MyObjectMapDialog } from "./myObject-map/ObjectMapDialog";
import { MyObjectsMapDialog } from "./myObjects-map/MyObjectsMapDialog";
import { MyObjectsCard } from "./MyObjectsCard";
import { MyObjectsUIProvider } from "./MyObjectsUIContext";
import { ObjectsEditDialog } from "./objects-edit-dialog/ObjectsEditDialog";
import { ObjectsLoadingDialog } from "./objects-loading-dialog/ObjectsLoadingDialog";

export const MyObjectsPage = ({ history }) => {
  const myObjectsUIEvents = {
    newObjectButtonClick: () => {
      history.push(`${ROUTES.USER_OBJECTS}/new`);
    },

    objectsMapButtonClick: () => {
      history.push(`${ROUTES.USER_OBJECTS}/map`);
    },

    objectMapButtonClick: (id) => {
      history.push(`${ROUTES.USER_OBJECTS}/${id}/location/`);
    },
  };

  return (
    <MyObjectsUIProvider myObjectsUIEvents={myObjectsUIEvents}>
      <ObjectsLoadingDialog />
      <Route path={`${ROUTES.USER_OBJECTS}/map`}>
        {({ history, match }) => (
          <MyObjectsMapDialog
            show={match != null}
            onHide={() => {
              history.push(ROUTES.USER_OBJECTS);
            }}
          />
        )}
      </Route>
      <Route path={`${ROUTES.USER_OBJECTS}/:id/location`}>
        {({ history, match }) => (
          <MyObjectMapDialog
            id={match && match.params.id}
            show={match != null}
            onHide={() => {
              history.push(ROUTES.USER_OBJECTS);
            }}
          />
        )}
      </Route>
      <Route path={`${ROUTES.USER_OBJECTS}/new`}>
        {({ history, match }) => (
          <ObjectsEditDialog
            show={match != null}
            onHide={() => {
              history.push(ROUTES.USER_OBJECTS);
            }}
          />
        )}
      </Route>
      <MyObjectsCard />
    </MyObjectsUIProvider>
  );
};