import { ENDPOINT, TOKEN } from './daDataServices.models';

class DaDataServices {
  constructor() {
    this.endpoint = ENDPOINT;
  }

  getOrganizationDescription({ userQuery }) {
    return fetch(`${this.endpoint}/party`, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Token ' + TOKEN,
      },
      body: JSON.stringify({
        query: userQuery,
        count: 20,
        locations_boost: [
          {
            kladr_id: '18',
          },
        ],
      }),
    })
      .then(response => {
        if (!response.ok) {
          throw new Error(response.status);
        }
        return response.json();
      })
      .catch(e => {
        throw new Error(e.message);
      });
  }

  getAddress({ userQuery }) {
    return fetch(`${this.endpoint}/address`, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Token ' + TOKEN,
      },
      body: JSON.stringify({
        query: userQuery,
        count: 5,
        locations_boost: [
          {
            kladr_id: '18',
          },
        ],
      }),
    })
      .then(response => {
        if (!response.ok) {
          throw new Error(response.status);
        }
        return response.json();
      })
      .catch(e => {
        throw new Error(e.message);
      });
  }
}

export const daDataServices = new DaDataServices();
