import React, { createContext, useContext, useState, useCallback } from 'react';
import { isEqual, isFunction } from 'lodash';
import { initialFilter } from './MyObjectsUIHelpers';

const MyObjectsUIContext = createContext();

export function useMyObjectsUIContext() {
  return useContext(MyObjectsUIContext);
}

export const MyObjectsUIConsumer = MyObjectsUIContext.Consumer;

export function MyObjectsUIProvider({ myObjectsUIEvents, children }) {
  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const [ids, setIds] = useState([]);
  const setQueryParams = useCallback(nextQueryParams => {
    setQueryParamsBase(prevQueryParams => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);

  const initCalculation = {
    id: undefined,
    date: '',
    object: '',
    oid: '',
    status: '',
    title: '',
    type: '',
  };

  const value = {
    queryParams,
    setQueryParamsBase,
    ids,
    setIds,
    setQueryParams,
    initCalculation,
    newObjectButtonClick: myObjectsUIEvents.newObjectButtonClick,
    objectsMapButtonClick: myObjectsUIEvents.objectsMapButtonClick,
    objectMapButtonClick: myObjectsUIEvents.objectMapButtonClick,
  };

  return <MyObjectsUIContext.Provider value={value}>{children}</MyObjectsUIContext.Provider>;
}
